import { ACCESS_TREE_OBJECT_TYPE } from 'web-frontend-component-library/enums';
import { IAccessTreeItem } from 'web-frontend-component-library/interfaces';
import { IExpiryDateIcons } from "@shared/services/expiry-date-icons-helper.service";

export enum STORAGE_KEYS {
  ACCESS_OBJECTS = 'ASUER-AccessObjects',
  ACCESS_RIGHTS = 'ASUER-AccessRights',
  USER_MENU = 'ASUER-UserMenu',
  MENU_OPENED = 'ASUER-MenuOpened',
  USER_INFO = 'ASUER-UserInfo',
  REDIRECT_TO = 'ASUER-RedirectTo',
  AUTH = 'ASUER-Auth',
  AUTH_STATE = 'ASUER-AuthState',
  SYSTEM_PARAMS = 'ASUER-SystemParams',
  PUBLIC_SYSTEM_PARAMS = 'ASUER-PublicSystemParams',
}
export const MENU_OPENED_URLS = ['/main'];
export const MENU_URLS = {
  g_Catalog: '/nsi/dictionaries',
  g_Group: '/admin/settings-access/groups',
  g_Role: '/admin/settings-access/roles',
  g_User: '/admin/settings-access/users',
  g_System: '/admin/parameters/system-parameters',
  g_TaskSchedule: '/admin/parameters/task-schedule',
  g_EventLog: '/admin/monitoring/event-log',
  g_IVKEvents: '/admin/monitoring/event-log-ivk',
  g_DBTests: '/admin/monitoring/db-tests',
  g_Uploads: '/admin/monitoring/uploads-journal',
  g_Downloads: '/admin/monitoring/downloads-journal',
  g_SoftwareRentalContract: '/admin/settings-access/software-rent-contracts',
  g_DynamicStateModel: '/admin/parameters/status-model',
  g_WebProxy: '/admin/parameters/web-proxy',
  g_AdminCatalog: '/admin/parameters/dictionaries',
  g_MeasureObjects: '/energy-resources/measure-objects',
  g_MeasurePoints: '/energy-resources/measure-points',
  g_Devices: '/energy-resources/devices',
  g_MeteoStations: '/energy-resources/meteostations',
  g_DeliveryContracts: '/contracts/delivery-contracts',
  g_MnemonicScheme: '/energy-resources/settings/mnemoscheme',
  g_MeasureReportTmpl: '/energy-resources/settings/template-report',
  g_RegimeMap: '/energy-resources/settings/regime-maps',
  g_FormulasRegister: '/energy-resources/settings/formulas-registry',
  g_TemperatureGraphs: '/energy-resources/settings/temperature-graphs',
  g_Profile: '/personal-area/profile',
  g_PUploads: '/personal-area/monitoring/uploads-journal',
  g_PDownloads: '/personal-area/monitoring/downloads-journal',
  g_PEventLog: '/personal-area/monitoring/event-log',
  g_Problems: '/dispatcher/incidents',
  g_FuelAccounting: '/energy-resources/fuel',
  g_CheckupPlans: '/maintainence/checkup-plans',
  g_MaintainencePlans: '/maintainence/maintainence-plans',
  g_MaintainenceCrew: '/maintainence/maintainence-crew',
  g_AWPeriodPlans: '/maintainence/winter-plans',
  g_WorkContracts: '/maintainence/settings/contracts',
  g_EmergencyWorkRequest: '/maintainence/emergency-work-request',
  g_EmergencySituations: '/maintainence/emergency-log',
  g_ResourceDisablePlans: '/maintainence/resource-disable-plans',
  g_VerificationPlans: '/maintainence/verification-plans',
  g_NetworksHE: '/energy-systems/networks/heat-energy',
  g_NetworksCW: '/energy-systems/networks/cold-water',
  g_NetworksHW: '/energy-systems/networks/hot-water',
  g_NetworksEE: '/energy-systems/networks/electric-energy',
  g_NetworksGas: '/energy-systems/networks/gas',
  g_NetworksDW: '/energy-systems/networks/disposal-water',
  g_Visualization: '/analytics/visualisation',
  g_Reports: '/analytics/reports'
};
export const MENU_ICONS = {
  m_NSI: 'icon-menu_nsi',
  m_Admin: 'icon-menu_admin',
  m_EnergyResources: 'icon-menu_immac',
  m_Contracts: 'icon-menu_contracts',
  m_PersPage: 'icon-menu_personal_page',
  m_Dispatcher: 'icon-menu_dispatcher',
  m_Maintainence: 'icon-menu_toir',
  m_EnergySystems: 'icon-menu_energy_systems',
  m_Analytics: 'icon-menu_analitic'
};

export const MENU_ADDITIONAL: IAccessTreeItem[] = [
  {
    id: 'm_PersPage',
    code: 'm_PersPage',
    name: 'Личный кабинет',
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
    parentId: null,
    sortOrder: 1000,
  },
  {
    id: 'g_Profile',
    code: 'g_Profile',
    name: 'Профиль',
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: 'm_PersPage',
    sortOrder: 10,
  },
  {
    id: 'm_PEventsLog',
    code: 'm_PEventsLog',
    name: 'Журналы действий',
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
    parentId: 'm_PersPage',
    sortOrder: 20,
  },
  {
    id: 'g_PUploads',
    code: 'g_PUploads',
    name: 'Загрузки',
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: 'm_PEventsLog',
    sortOrder: 10,
  },
  {
    id: 'g_PDownloads',
    code: 'g_PDownloads',
    name: 'Выгрузки',
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: 'm_PEventsLog',
    sortOrder: 20,
  },
  {
    id: 'g_PEventLog',
    code: 'g_PEventLog',
    name: 'События',
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
    parentId: 'm_PEventsLog',
    sortOrder: 30,
  },
];

export enum ASUER_SYSTEM_PARAMETER_NAME {
  NSI_ADDRESS_READONLY = 'NSI_ADDRESS_READONLY',
  MEASURE_OBJECT_READONLY = 'MEASURE_OBJECT_READONLY',
  NSI_ORGANIZATION_READONLY = 'NSI_ORGANIZATION_READONLY',
  CURRENT_SYSTEM_CODE = 'CURRENT_SYSTEM_CODE',
  LAST_DATE_OAS_AUTOMATIC_MEASUREMENT = 'LAST_DATE_OAS_AUTOMATIC_MEASUREMENT',
  LAST_DATE_OAS_CHECK_DATA_QUALITY = 'LAST_DATE_OAS_CHECK_DATA_QUALITY'
}

export enum ANALYTICS_SYSTEM_PARAMETER_NAME {
  LAST_DATE_OAS_REPORTS_LIST_OF_DEVICES_AUTO = 'LAST_DATE_OAS_REPORTS_LIST_OF_DEVICES_AUTO',
  LAST_DATE_OAS_CHECK_DATA_QUALITY = 'LAST_DATE_OAS_CHECK_DATA_QUALITY',
  LAST_DATE_OAS_REPORTS_TEMPERATURE = 'LAST_DATE_OAS_REPORTS_TEMPERATURE',
  LAST_DATE_OAS_REPORTS_FUEL_MANUAL = 'LAST_DATE_OAS_REPORTS_FUEL_MANUAL'
}

export enum GRID_ID {
  'ADMIN.USERS' = 'admin.users',
  'ADMIN.GROUPS' = 'admin.groups',
  'ADMIN.ROLES' = 'admin.roles',
  'ADMIN.SYSTEM_PARAMETERS' = 'admin.system_parameters',
  'ADMIN.TASK_SCHEDULE' = 'admin.task_schedule',
  'ADMIN.SOFTWARE_RENT_CONTRACT' = 'admin.software_rent_contract',
  'ADMIN.SOFTWARE_RENT_CONTRACT_VERSIONS' = 'admin.software_rent_contract_versions',
  'ADMIN.SOFTWARE_RENT_CONTRACT_VERSIONS_ADD_USERS' = 'admin.software_rent_contract_versions_add_users',
  'ADMIN.SOFTWARE_RENT_CONTRACT_VERSIONS_ADD_OBJECTS' = 'admin.software_rent_contract_versions_add_objects',
  'ADMIN.GROUPS.ADDRESS' = 'admin.groups.address',
  'ADMIN.MONITORING.DB_TESTS_LIST' = 'admin.monitoring.db-tests-list',
  'ADMIN.MONITORING.DB_TESTS_RESULTS' = 'admin.monitoring.db-tests-results',
  'ADMIN.MONITORING.UPLOADS_JOURNAL' = 'admin.monitoring.uploads-journal',
  'ADMIN.MONITORING.UPLOADS_JOURNAL_RESULTS' = 'admin.monitoring.uploads-journal-results',
  'ADMIN.MONITORING.DOWNLOADS_JOURNAL' = 'admin.monitoring.download-journal',
  'ADMIN.STATUS_MODEL' = 'admin.status-model',
  'ADMIN.STATUS_MODEL.TRANSITIONS' = 'admin.status-model.transitions',
  'ADMIN.STATUS_MODEL.STATUSES' = 'admin.status-model.statuses',
  'ENERGY_RESOURCES.MEASURE_OBJECTS' = 'energy_resources.measure_objects',
  'ENERGY_RESOURCES.MEASURE_OBJECT.HISTORY_CONTRACT_VERSIONS' = 'energy_resources.measure_objects.history_contract_versions',
  'ENERGY_RESOURCES.DEVICES' = 'energy_resources.devices',
  'ENERGY_RESOURCES.ENGINEER_DEVICES' = 'energy_resources.engineer_devices',
  'ENERGY_RESOURCES.ACCESS_NODES' = 'energy_resources.access-nodes',
  'ENERGY_RESOURCES.MEASURE_POINTS' = 'energy_resources.measure_points',
  'ENERGY_RESOURCES.MEASURE_POINTS.TEMPERATURE_GRAPHS' = 'energy_resources.measure_points.temperature_graphs',
  'ENERGY_RESOURCES.CONNECT_USPD' = 'energy_resources.connect_uspd',
  'ENERGY_RESOURCES.CONNECT_UPD' = 'energy_resources.connect_upd',
  'ENERGY_RESOURCES.CONNECT_IU' = 'energy_resources.connect_iu',
  'ENERGY_RESOURCES.CONNECT_TU' = 'energy_resources.connect_tu',
  'ENERGY_RESOURCES.CONNECT_PU' = 'energy_resources.connect_pu',
  'ENERGY_RESOURCES.HISTORY_CONNECTION_STATES' = 'energy_resources.history_connection-states',
  'ENERGY_RESOURCES.HISTORY_STATES' = 'energy_resources.history_states',
  'ENERGY_RESOURCES.HISTORY_CONNECT_USD_TO_OU' = 'energy_resources.history_connect_usd_to_ou',
  'ENERGY_RESOURCES.HISTORY_CONNECT_TU_TO_PU_ON_OU' = 'energy_resources.history_connect_tu_to_pu_on_ou',
  'ENERGY_RESOURCES.HISTORY_CONNECT_IU_TO_TU_ON_OU' = 'energy_resources.history_connect_iu_to_tu_on_ou',
  'ENERGY_RESOURCES.HISTORY_CONNECT_USPD_TO_OU' = 'energy_resources.history_connect_uspd_to_ou',
  'ENERGY_RESOURCES.HISTORY_CONNECT_PU_TO_USPD' = 'energy_resources.history_connect_pu_to_uspd',
  'ENERGY_RESOURCES.HISTORY_CONNECT_UPD_TO_OU' = 'energy_resources.history_connect_upd_to_ou',
  'ENERGY_RESOURCES.HISTORY_CONNECT_PU_TO_UPD' = 'energy_resources.history_connect_pu_to_upd',
  'ENERGY_RESOURCES.HISTORY_CONNECT_TU_TO_PU_ON_PU' = 'energy_resources.history_connect_tu_to_pu_on_pu',
  'ENERGY_RESOURCES.HISTORY_CONNECT_PU_TO_USD' = 'energy_resources.history_connect_pu_to_usd',
  'ENERGY_RESOURCES.HISTORY_CONNECT_IU_TO_TU_ON_IU' = 'energy_resources.history_connect_iu_to_tu_on_iu',
  'ENERGY_RESOURCES.HISTORY_CONNECT_TU_TO_PU_ON_TU' = 'energy_resources.history_connect_tu_to_pu_on_tu',
  'ENERGY_RESOURCES.HISTORY_CONNECT_IU_TO_TU_ON_TU' = 'energy_resources.history_connect_iu_to_tu_on_tu',
  'ENERGY_RESOURCES.HISTORY_CONNECT_ENGINEER_DEVICES_ON_OU' = 'energy_resources.history_connect_engineer_devices_on_ou',
  'ENERGY_RESOURCES.EVENT_LOG' = 'energy_resources.event_log',
  'ENERGY_RESOURCES.INSTANT_PARAMS' = 'energy_resources.instant_params',
  'ENERGY_RESOURCES.ARCHIVE_PARAMS' = 'energy_resources.archive_params',
  'ENERGY_RESOURCES.INPUT_PARAMS' = 'energy_resources.input_params',
  'ENERGY_RESOURCES.CONFIG_JOURNAL' = 'energy_resources.config_journal',
  'ENERGY_RESOURCES.MEASURE_OBJECT.PICTURES' = 'energy_resources.measure_objects.pictures',
  'ENERGY_RESOURCES.MEASURE_OBJECT.DOCUMENTS' = 'energy_resources.measure_objects.documents',
  'ENERGY_RESOURCES.DEVICES.DOCUMENTS' = 'energy_resources.devices.documents',
  'ENERGY_RESOURCES.DEVICES.VERIFICATION_DOCUMENTS' = 'energy_resources.devices.verification_documents',
  'ENERGY_RESOURCES.DEVICES.EXPLOITATION_DOCUMENTS' = 'energy_resources.devices.exploitation_documents',
  'ENERGY_RESOURCES.DEVICES.STAGING_DOCUMENTS' = 'energy_resources.devices.staging_documents',
  'ENERGY_RESOURCES.WARRANTY' = 'energy_resources.warranty',
  'ENERGY_RESOURCES.TEMPLATE_REPORT' = 'energy_resources.template_report',
  'ENERGY_RESOURCES.TEMPLATE_REPORT.FILE_HISTORY' = 'energy_resources.template_report.file_history',
  'ENERGY_RESOURCES.TEMPLATE_REPORT_DICTIONARY' = 'energy_resources.template_report_dictionary',
  'ENERGY_RESOURCES.REGIME_MAPS' = 'energy_resources.regime_map',
  'ENERGY_RESOURCES.TEMPERATURE_GRAPHS' = 'energy_resources.temperature-graphs',
  'ENERGY_RESOURCES.TEMPERATURE_GRAPHS.HISTORY' = 'energy_resources.temperature-graphs.history',
  'ENERGY_RESOURCES.TEMPERATURE_GRAPHS.TU' = 'energy_resources.temperature-graphs.tu',
  'ENERGY_RESOURCES.REGIME_MAPS_VERSION_PARAMS' = 'energy_resources.regime_map_version_params',
  'ENERGY_RESOURCES.MEASURE_OBJECT_ENGINEER_DEVICES' = 'energy_resources.measure-object-engineer-devices',
  'ENERGY_RESOURCES.ENGINEER_DEVICE.HISTORY.PARENT_DEVICES' = 'energy_resources.engineer-device.history.parent-devices',
  'ENERGY_RESOURCES.ENGINEER_DEVICE.HISTORY.CHILD_DEVICES' = 'energy_resources.engineer-device.history.child-devices',
  'ENERGY_RESOURCES.ENGINEER_DEVICE.CONNECTED_DEVICES' = 'energy_resources.engineer-device.connected-devices',
  'ENERGY_RESOURCES.ENGINEER_DEVICE.CONNECTABLE_DEVICES' = 'energy_resources.engineer-device.connectable-devices',
  'ENERGY_RESOURCES.PSI_JOURNAL' = 'energy_resources.psi_journal',
  'ENERGY_RESOURCES.SUBCONSUMERS' = 'energy_resources.subconsumers',
  'ENERGY_RESOURCES.FUEL' = 'energy_resources.fuel',
  'ENERGY_RESOURCES.METEOSTATIONS' = 'energy_resources.meteostations',
  'ENERGY_RESOURCES.METEOSTATIONS.JOURNAL' = 'energy_resources.meteostations.journal',
  'ENERGY_RESOURCES.METEOSTATIONS.CONNECTIONS' = 'energy_resources.meteostations.connections',
  'ENERGY_RESOURCES.METEOSTATIONS.HISTORY' = 'energy_resources.meteostations.history',
  'EVENT_LOG' = 'event_log',
  'EVENT_LOG_IVK' = 'ivk_events',
  'SUPPLY_CONTRACTS' = 'supply_contracts',
  'SUPPLY_CONTRACTS.DOCS' = 'supply_contracts.docs',
  'SUPPLY_CONTRACTS.HISTORY' = 'supply_contracts.history',
  'SUPPLY_CONTRACTS.ADDITION.MEASURE_POINTS' = 'supply_contracts.addition.measure_points',
  'SUPPLY_CONTRACTS.ADDITION.ADD_MP' = 'supply_contracts.addition.add_mp',
  'SUPPLY_CONTRACTS.ADDITION.INHERIT_MP' = 'supply_contracts.addition.inherit_mp',
  'SUPPLY_CONTRACTS.ADDITION.POINTS.PARAMS' = 'supply_contracts.addition.points.params',
  'MEASURE_POINT.REPORT' = 'measure_point.reports',
  'MEASURE_POINT.REPORT.VERSION' = 'measure_point.report.versions',
  'MEASURE_POINT.REGIME_MAPS' = 'measure_point.regime_maps',
  'MEASURE_POINT.DISPATCHER.INCIDENTS_STATES_LOG' = 'measure_point.dispatcher.incidents_states_log',
  'MEASURE_OBJECT.REGIME_SEASONS' = 'measure_object.regime_seasons',
  'MEASURE_OBJECT.WORK_JOURNAL.PARAMS' = 'measure_object.work_journal.params',
  'MEASURE_OBJECT.WORK_JOURNAL.FILES' = 'measure_object.work_journal.files',
  'MEASURE_OBJECT.DISPATCHER.INCIDENTS' = 'measure_object.dispatcher.incidents',
  'MEASURE_OBJECT.FUEL.ACCOUNTING' = 'measure_object.fuel.accounting',
  'MEASURE_OBJECT.FUEL.ACCOUNTING.FILES_HISTORY' = 'measure_object.fuel.accounting.files_history',
  'MEASURE_OBJECT.FUEL.DELIVERY' = 'measure_object.fuel.delivery',
  'MEASURE_OBJECT.FUEL.CONSUMPTION' = 'measure_object.fuel.consumption',
  'MEASURE_OBJECT.FUEL.RELOCATION' = 'measure_object.fuel.relocation',
  'MEASURE_OBJECT.FUEL.INVENTORY' = 'measure_object.fuel.inventory',
  'MEASURE_OBJECT.FUEL.LOG' = 'measure_object.fuel.log',
  'MEASURE_OBJECT.NUR.ADD_BOILERS' = 'measure_object.nur.add-boilers',
  'MEASURE_OBJECT.NUR.EDIT_BOILERS' = 'measure_object.nur.edit-boilers',
  'MEASURE_OBJECT.NETWORKS' = 'measure_object.networks',
  'MEASURE_POINT.SUBCONSUMERS' = 'measure_point.subconsumers',
  'MEASURE_DEVICE.DISPATCHER.INCIDENTS_STATES_LOG' = 'measure_device.dispatcher.incidents_state_log',
  'PERSONAL_AREA.MONITORING.DOWNLOADS_JOURNAL' = 'personal_area.monitoring.downloads_journal',
  'PERSONAL_AREA.MONITORING.EVENT_LOG_JOURNAL' = 'personal_area.monitoring.event_log_journal',
  'PERSONAL_AREA.MONITORING.UPLOADS_JOURNAL' = 'personal_area.monitoring.uploads_journal',
  'PERSONAL_AREA.MONITORING.UPLOADS_JOURNAL_RESULTS' = 'personal_area.monitoring.uploads_journal_results',
  'ENERGY_RESOURCES.MEASURE_OBJECT.OUTSIDE_TEMP_JOURNAL' = 'energy_resources.measure_object.outside_temp_journal',
  'ENERGY_RESOURCES.MEASURE_OBJECT.OUTSIDE_TEMP_JOURNAL.HISTORY' = 'energy_resources.measure_object.outside_temp_journal.history',
  'ENERGY_RESOURCES.MEASURE_OBJECT.OUTSIDE_TEMP_JOURNAL.CHANGES' = 'energy_resources.measure_object.outside_temp_journal.changes',
  'ADMIN.WEB_PROXY' = 'admin.web_proxy',
  'ADMIN.WEB_PROXY_JOURNAL' = 'admin.web_proxy_journal',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY' = 'energy_resources.settings.formulas_registry',
  'DISPATCHER.INCIDENTS' = 'dispatcher.incidents',
  'DISPATCHER.INCIDENTS.INCIDENTS_STATES_LOG' = 'dispatcher.incidents_states_log',
  'DISPATCHER.INCIDENTS.REGIME_MAPS' = 'dispatcher.incidents.regime_maps',
  'DISPATCHER.INCIDENTS.EMERGENCY_WORK_REQUEST' = 'dispatcher.incidents.emergency_work_request',
  'MAINTAINENCE.CHECKUP_PLANS' = 'maintainence.checkup_plans',
  'MAINTAINENCE.CHECKUP_PLANS.HISTORY' = 'maintainence.checkup_plans.history',
  'MAINTAINENCE.CHECKUP_PLANS.ACTIONS' = 'maintainence.checkup_plans.actions',
  'MAINTAINENCE.CHECKUP_PLANS.ACTIONS.ADD_DEVICE' = 'maintainence.checkup_plans.actions.add_device',
  'MAINTAINENCE.CHECKUP_PLANS.ACTIONS.JOBS' = 'maintainence.checkup_plans.actions.jobs',
  'MAINTAINENCE.MAINTAINENCE_PLANS' = 'maintainence.maintainence_plans',
  'MAINTAINENCE.MAINTAINENCE_PLANS.HISTORY' = 'maintainence.maintainence_plans.history',
  'MAINTAINENCE.MAINTAINENCE_PLANS.ACTIONS' = 'maintainence.maintainence_plans.actions',
  'MAINTAINENCE.MAINTAINENCE_PLANS.ACTIONS.ADD_DEVICE' = 'maintainence.maintainence_plans.actions.add_device',
  'MAINTAINENCE.MAINTAINENCE_PLANS.ACTIONS.JOBS' = 'maintainence.maintainence_plans.actions.jobs',
  'MAINTAINENCE.MAINTAINENCE_CREW' = 'maintainence.maintainence_crew',
  'MAINTAINENCE.MAINTAINENCE_CREW.USERS' = 'maintainence.maintainence_crew.users',
  'MAINTAINENCE.MAINTAINENCE_CREW.ADD_USERS' = 'maintainence.maintainence_crew.add_users',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST' = 'maintainence.emergency_work_request',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.HISTORY' = 'maintainence.emergency_work_request.history',
  'MAINTAINENCE.CREW_MAINTAINENCE_PLANS' = 'maintainence.crew.maintainence_plans',
  'MAINTAINENCE.CREW_CHECKUP_PLANS' = 'maintainence.crew.checkup_plans',
  'MAINTAINENCE.WINTER_PLANS' = 'maintainence.winter_plans',
  'MAINTAINENCE.WINTER_PLANS.HISTORY' = 'maintainence.winter_plans.history',
  'MAINTAINENCE.WINTER_PLANS.ACTIONS' = 'maintainence.winter_plans.actions',
  'MAINTAINENCE.WINTER_PLANS.ACTIONS.ADD_DEVICE' = 'maintainence.winter_plans.actions.add_device',
  'MAINTAINENCE.WINTER_PLANS.ACTIONS.JOBS' = 'maintainence.winter_plans.actions.jobs',
  'MAINTAINENCE.CREW_PREPARE_PLANS' = 'maintainence.crew.prepare_plans',
  'MAINTAINENCE.WORK_CONTRACTS' = 'maintainence.work_contracts',
  'MAINTAINENCE.WORK_CONTRACTS.EMERGENCY_WORK_REQUEST' = 'maintainence.work_contracts.emergency_work_request',
  'MAINTAINENCE.WORK_CONTRACTS.CHECKUP_PLANS' = 'maintainence.work_contracts.checkup_plans',
  'MAINTAINENCE.WORK_CONTRACTS.MAINTAINENCE_PLANS' = 'maintainence.work_contracts.maintainence_plans',
  'MAINTAINENCE.WORK_CONTRACTS.PREPARE_PLANS' = 'maintainence.work_contracts.prepare_plans',
  'MAINTAINENCE.EMERGENCY_LOG' = 'maintainence.emergency-log',
  'MAINTAINENCE.EMERGENCY_LOG.MEASURE_POINTS' = 'maintainence.emergency-log.measure-points',
  'MAINTAINENCE.EMERGENCY_LOG.MEASURE_POINTS.ADD' = 'maintainence.emergency-log.measure-points.add',
  'MAINTAINENCE.EMERGENCY_LOG.HISTORY' = 'maintainence.emergency-log.history',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS' = 'maintainence.resource_disable_plans',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.MEASURE_POINTS' = 'maintainence.resource_disable_plans.measure_points',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.JOURNAL' = 'maintainence.resource_disable_plans.journal',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.JOURNAL.MEASURE_POINTS' = 'maintainence.resource_disable_plans.journal.measure_points',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.HISTORY' = 'maintainence.resource_disable_plans.history',
  'MAINTAINENCE.VERIFICATION_PLANS' = 'maintainence.verification_plans',
  'MAINTAINENCE.VERIFICATION_PLANS.HISTORY' = 'maintainence.verification_plans.history',
  'MAINTAINENCE.VERIFICATION_PLANS.DEVICES' = 'maintainence.verification_plans.devices',
  'ENERGY_SYSTEMS.NETWORKS' = 'energy_systems.networks.',
  'ENERGY_SYSTEMS.NETWORKS.SECTIONS' = 'energy_systems.networks.sections',
  'ENERGY_SYSTEMS.NETWORKS.SECTIONS.OPERATION_HISTORY' = 'energy_systems.networks.sections.operation_history',
  'ENERGY_SYSTEMS.NETWORKS.SECTIONS.PIPES' = 'energy_systems.networks.sections.pipes',
  'ENERGY_SYSTEMS.NETWORKS.SECTIONS.CABLES' = 'energy_systems.networks.sections.cables',
  'ENERGY_SYSTEMS.NETWORKS.CONFIGURATIONS' = 'energy_systems.networks.configurations',
  'ENERGY_SYSTEMS.NETWORKS.CONFIGURATIONS.SECTIONS.PIPES' = 'energy_systems.networks.configurations.sections.pipes',
  'ENERGY_SYSTEMS.NETWORKS.MODES' = 'energy_systems.networks.modes',
  'ENERGY_SYSTEMS.NETWORKS.NORM_LOSSES' = 'energy_systems.networks.norm-losses',
  'ENERGY_SYSTEMS.NETWORKS.BALANCES' = 'energy_systems.networks.balances',
}

export enum USER_ACCESS {
  'ADMIN' = 'm_Admin',
  // Роли
  'ROLE' = 'g_Role',
  'ROLE.card' = 'c_Role',
  'ROLE.card_add' = 'c_Role.cf_add',
  'ROLE.card_edit' = 'c_Role.cf_edit',
  'ROLE.card_del' = 'c_Role.cf_del',
  // Группы
  'GROUP' = 'g_Group',
  'GROUP.card' = 'c_Group',
  'GROUP.card_add' = 'c_Group.cf_add',
  'GROUP.card_edit' = 'c_Group.cf_edit',
  'GROUP.card_del' = 'c_Group.cf_del',
  // Пользователи
  'USER' = 'g_User',
  'USER.card' = 'c_User',
  'USER.card_edit' = 'c_User.cf_edit',
  'USER.card_software_rent_contract_version' = 'c_UserSoftwareRentalContractVersion',
  'USER.card_software_rent_contract_version_add' = 'c_UserSoftwareRentalContractVersion.cf_add',
  'USER.card_software_rent_contract_version_edit' = 'c_UserSoftwareRentalContractVersion.cf_edit',
  'USER.card_software_rent_contract_version_del' = 'c_UserSoftwareRentalContractVersion.cf_del',
  'USER.card_software_rent_contract_version_finish_now' = 'c_UserSoftwareRentalContractVersion.cf_FinishNow',
  'USER.card_software_rent_contract_version_finish_date' = 'c_UserSoftwareRentalContractVersion.cf_FinishDate',
  'USER.grid_repl' = 'g_User.gf_repl',
  // Системные параметры
  'SYSTEM' = 'g_System',
  'SYSTEM.card' = 'c_System',
  'SYSTEM.card_edit' = 'c_System.cf_edit',
  // Расписание запуска задач
  'TASKSCHEDULE' = 'g_TaskSchedule',
  'TASKSCHEDULE.card' = 'c_TaskSchedule',
  'TASKSCHEDULE.card_edit' = 'c_TaskSchedule.cf_edit',
  // Мониторинг
  'EVENT_LOG' = 'g_EventLog',
  'EVENT_LOG.REPORTS' = 'g_EventLog.gf_Reports',
  'DB_TESTS_MONITORING' = 'g_DBTests',
  'EVENT_LOG_IVK' = 'g_IVKEvents',
  'NSI_CATALOG' = 'g_Catalog',
  'ADMIN_CATALOG' = 'g_AdminCatalog',
  'UPLOADS_JOURNAL' = 'g_Uploads',
  'DOWNLOADS_JOURNAL' = 'g_Downloads',
  // Договоры аренды ПО
  'SOFTWARE_RENT_CONTRACT' = 'g_SoftwareRentalContract',
  'SOFTWARE_RENT_CONTRACT.card' = 'c_SoftwareRentalContract',
  'SOFTWARE_RENT_CONTRACT.card_add' = 'c_SoftwareRentalContract.cf_add',
  'SOFTWARE_RENT_CONTRACT.card_edit' = 'c_SoftwareRentalContract.cf_edit',
  'SOFTWARE_RENT_CONTRACT.card_del' = 'c_SoftwareRentalContract.cf_del',
  'SOFTWARE_RENT_CONTRACT_VERSION.card' = 'c_SoftwareRentalContractVersion',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_add' = 'c_SoftwareRentalContractVersion.cf_add',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_edit' = 'c_SoftwareRentalContractVersion.cf_edit',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_del' = 'c_SoftwareRentalContractVersion.cf_del',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_finish_date' = 'c_SoftwareRentalContractVersion.cf_FinishDate',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_add_user' = 'c_SoftwareRentalContractVersion.cf_addUser',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_del_user' = 'c_SoftwareRentalContractVersion.cf_delUser',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_transfer_user' = 'c_SoftwareRentalContractVersion.cf_transferUser',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_user_now' = 'c_SoftwareRentalContractVersion.cf_disconnectUserNow',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_user_date' = 'c_SoftwareRentalContractVersion.cf_disconnectUserDate',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_add_object' = 'c_SoftwareRentalContractVersion.cf_addObject',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_del_object' = 'c_SoftwareRentalContractVersion.cf_delObject',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_transfer_object' = 'c_SoftwareRentalContractVersion.cf_transferObject',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_object_now' = 'c_SoftwareRentalContractVersion.cf_disconnectObjectNow',
  'SOFTWARE_RENT_CONTRACT_VERSION.card_disconnect_object_date' = 'c_SoftwareRentalContractVersion.cf_disconnectObjectDate',
  // Статусная модель
  'STATUS_MODEL.grid' = 'g_DynamicStateModel',
  'STATUS_MODEL.card' = 'c_DynamicStateModel',
  'STATUS_MODEL.card.transition_add' = 'c_DynamicStateModel.cf_StateTransitionAdd',
  'STATUS_MODEL.card.transition_edit' = 'c_DynamicStateModel.cf_StateTransitionEdit',
  'STATUS_MODEL.card.transition_delete' = 'c_DynamicStateModel.cf_StateTransitionDel',
  'STATUS_MODEL.card.status_add' = 'c_DynamicStateModel.cf_StateAdd',
  'STATUS_MODEL.card.status_edit' = 'c_DynamicStateModel.cf_StateEdit',
  'STATUS_MODEL.card.status_delete' = 'c_DynamicStateModel.cf_StateDel',
  // Учет ресурсов
  'ENERGY_RESOURCES' = 'm_ЕnergyResources',
  // ОУ
  'MEASURE_OBJECT' = 'g_MeasureObjects',
  'MEASURE_OBJECT.reports' = 'g_MeasureObjects.gf_DownloadReports',
  'MEASURE_OBJECT.card' = 'c_MeasureObject',
  'MEASURE_OBJECT.card.history' = 'c_MeasureObject.ct_History',
  'MEASURE_OBJECT.card.documents' = 'c_MeasureObject.ct_Documents',
  'MEASURE_OBJECT.card.pictures' = 'c_MeasureObject.ct_Pictures',
  'MEASURE_OBJECT.card.dispatching' = 'c_MeasureObject.ct_Dispatching',
  'MEASURE_OBJECT.card.networks' = 'c_MeasureObject.ct_Networks',
  'MEASURE_OBJECT.card_add' = 'c_MeasureObject.cf_add',
  'MEASURE_OBJECT.card_edit' = 'c_MeasureObject.cf_edit',
  'MEASURE_OBJECT.card_del' = 'c_MeasureObject.cf_del',
  'MEASURE_OBJECT.card_contract_versions' = 'c_MeasureObject.cf_SoftwareRentalContract',
  'MEASURE_OBJECT.card_to_archive' = 'c_MeasureObject.cf_StatusToArchive',
  'MEASURE_OBJECT.card_from_archive' = 'c_MeasureObject.cf_StatusFromArchive',
  'MEASURE_OBJECT.card.pictures_add' = 'c_MeasureObject.cf_AddPicture',
  'MEASURE_OBJECT.card.pictures_del' = 'c_MeasureObject.cf_DeletePicture',
  'MEASURE_OBJECT.card.documents_add' = 'c_MeasureObject.cf_AddDocument',
  'MEASURE_OBJECT.card.documents_edit' = 'c_MeasureObject.cf_EditDocument',
  'MEASURE_OBJECT.card.documents_del' = 'c_MeasureObject.cf_DeleteDocument',
  'MEASURE_OBJECT.card.outside_temp_journal' = 'c_MeasureObject.ct_ExternalTemperatureJournal',
  'MEASURE_OBJECT.card.outside_temp_journal_add' = 'c_MeasureObject.cf_addNoteExternalTemperatureJournal',
  'MEASURE_OBJECT.card.outside_temp_journal_view' = 'c_MeasureObject.cf_viewCardExternalTemperatureJournal',
  'MEASURE_OBJECT.card.outside_temp_journal_edit' = 'c_MeasureObject.cf_editCardExternalTemperatureJournal',
  'MEASURE_OBJECT.card.outside_temp_journal_import' = 'c_MeasureObject.cf_importFileExternalTemperatureJournal',
  'MEASURE_OBJECT.card.nur' = 'c_MeasureObject.ct_NormativelySpecific',
  'MEASURE_OBJECT.card.nur.add' = 'c_MeasureObject.cf_AddCalculationNormative',
  'MEASURE_OBJECT.card.nur.edit' = 'c_MeasureObject.cf_EditCalculationNormative',
  'MEASURE_OBJECT.card.nur.calc' = 'c_MeasureObject.cf_ExecuteCalculationNormative',
  'MEASURE_OBJECT.card.nur.delete' = 'c_MeasureObject.cf_DeleteCalculationNormative',
  'MEASURE_OBJECT.card.own_needs.add' = 'c_MeasureObject.cf_AddOwnNeeds',
  'MEASURE_OBJECT.card.own_needs.edit' = 'c_MeasureObject.cf_EditOwnNeeds',
  'MEASURE_OBJECT.card.own_needs.delete' = 'c_MeasureObject.cf_DeleteOwnNeeds',
  'MEASURE_OBJECT.card.own_needs.row.add' = 'c_MeasureObject.cf_AddLineOwnNeeds',
  'MEASURE_OBJECT.card.own_needs.row.edit' = 'c_MeasureObject.cf_EditLineOwnNeeds',
  'MEASURE_OBJECT.card.own_needs.row.delete' = 'c_MeasureObject.cf_DeleteLineOwnNeeds',
  // Учет топлива
  'FUEL' = 'g_FuelAccounting',
  'FUEL.consumption.report.generate' = 'g_FuelAccounting.gf_ReportFuel',
  'MEASURE_OBJECT.card.fuel' = 'c_MeasureObject.ct_FuelAccounting',
  'MEASURE_OBJECT.card.fuel.accounting.add' = 'c_MeasureObject.cf_AddFuelResidueRate',
  'MEASURE_OBJECT.card.fuel.accounting.edit' = 'c_MeasureObject.cf_EditFuelResidueRate',
  'MEASURE_OBJECT.card.fuel.accounting.delete' = 'c_MeasureObject.cf_DeletFuelResidueRate',
  'MEASURE_OBJECT.card.fuel.delivery.add' = 'c_MeasureObject.cf_AddFuelSupply',
  'MEASURE_OBJECT.card.fuel.delivery_plan.add' = 'c_MeasureObject.cf_AddPlanFuelSupply',
  'MEASURE_OBJECT.card.fuel.consumption.add' = 'c_MeasureObject.cf_AddFuelSupply',
  'MEASURE_OBJECT.card.fuel.relocation.add' = 'c_MeasureObject.cf_AddFuelTransfer',
  'MEASURE_OBJECT.card.fuel.relocation.accept_transfer' = 'c_MeasureObject.cf_AcceptFuelTransfer',
  'MEASURE_OBJECT.card.fuel.relocation.refund_transfer' = 'c_MeasureObject.cf_ReturnFuelTransfer',
  'MEASURE_OBJECT.card.fuel.relocation.accept_refund_transfer' = 'c_MeasureObject.cf_AcceptFuelReturn',
  'MEASURE_OBJECT.card.fuel.inventory.add' = 'c_MeasureObject.cf_AddFuelInventory',
  'MEASURE_OBJECT.card.fuel.log.edit' = 'c_MeasureObject.cf_EditFuelChange',
  'MEASURE_OBJECT.card.fuel.log.delete' = 'c_MeasureObject.cf_DeletFuelChange',
  // УСПД
  'DEVICE_TRANSFER.card' = 'c_DeviceTransfer',
  'DEVICE_TRANSFER.card.history' = 'c_DeviceTransfer.ct_History',
  'DEVICE_TRANSFER.card.documents' = 'c_DeviceTransfer.ct_Documents',
  'DEVICE_TRANSFER.card.config' = 'c_DeviceTransfer.ct_Config',
  'DEVICE_TRANSFER.card.warranty' = 'c_DeviceTransfer.ct_Warranty',
  'DEVICE_TRANSFER.card.dispatching' = 'c_DeviceTransfer.ct_Dispatching',
  'DEVICE_TRANSFER.card.psi' = 'c_DeviceTransfer.ct_PSIjournal',
  'DEVICE_TRANSFER.card_add' = 'c_DeviceTransfer.cf_add',
  'DEVICE_TRANSFER.card_edit' = 'c_DeviceTransfer.cf_edit',
  'DEVICE_TRANSFER.card_del' = 'c_DeviceTransfer.cf_del',
  'DEVICE_TRANSFER.card_to_service' = 'c_DeviceTransfer.cf_StatusToService',
  'DEVICE_TRANSFER.card_to_archive' = 'c_DeviceTransfer.cf_StatusToArchive',
  'DEVICE_TRANSFER.card_from_archive' = 'c_DeviceTransfer.cf_StatusFromArchive',
  'DEVICE_TRANSFER.connect_to_measure_object' = 'c_DeviceTransfer.cf_ConnectToMeasureObject',
  'DEVICE_TRANSFER.disconnect_from_measure_object' = 'c_DeviceTransfer.cf_DisconnectFromMeasureObject',
  'DEVICE_TRANSFER.del_connect_to_measure_object' = 'c_DeviceTransfer.cf_DeleteConnectToMeasureObject',
  'DEVICE_TRANSFER.check_connect' = 'c_DeviceTransfer.cf_CheckConnectionUSPD',
  'DEVICE_TRANSFER.write_uspd_params' = 'c_DeviceTransfer.cf_WriteUspdParam',
  'DEVICE_TRANSFER.read_uspd_tree' = 'c_DeviceTransfer.cf_ReadUspdTree',
  'DEVICE_TRANSFER.get_instant_data' = 'c_DeviceTransfer.cf_GetInstantMeterDataUSPD',
  'DEVICE_TRANSFER.restart_uspd' = 'c_DeviceTransfer.cf_RestartUSPD',
  'DEVICE_TRANSFER.write_uspd_tree' = 'c_DeviceTransfer.cf_WriteUspdTree',
  'DEVICE_TRANSFER.reread_instant_data' = 'c_DeviceTransfer.cf_RereadMeterDataUSPD',
  'DEVICE_TRANSFER.generate_passport' = 'c_DeviceTransfer.cf_GeneratePassportUSPD',
  'DEVICE_TRANSFER.generate_ppi' = 'c_DeviceTransfer.cf_GeneratePPIUSPD',
  'DEVICE_TRANSFER.card.warranty_add' = 'c_DeviceTransfer.cf_AddWarranty',
  'DEVICE_TRANSFER.card.warranty_edit' = 'c_DeviceTransfer.cf_EditWarranty',
  'DEVICE_TRANSFER.card.warranty_del' = 'c_DeviceTransfer.cf_DeleteWarranty',
  'DEVICE_TRANSFER.card.documents_add' = 'c_DeviceTransfer.cf_AddDocument',
  'DEVICE_TRANSFER.card.documents_edit' = 'c_DeviceTransfer.cf_EditDocument',
  'DEVICE_TRANSFER.card.documents_del' = 'c_DeviceTransfer.cf_DeleteDocument',
  'DEVICE_TRANSFER.card.documents_verification_add' = 'c_DeviceTransfer.cf_AddDocumentVerification',
  'DEVICE_TRANSFER.card.documents_verification_edit' = 'c_DeviceTransfer.cf_EditDocumentVerification',
  'DEVICE_TRANSFER.card.documents_verification_del' = 'c_DeviceTransfer.cf_DeleteDocumentVerification',
  'DEVICE_TRANSFER.card.documents_exploitation_add' = 'c_DeviceTransfer.cf_AddDocumentExploitation',
  'DEVICE_TRANSFER.card.documents_exploitation_edit' = 'c_DeviceTransfer.cf_EditDocumentExploitation',
  'DEVICE_TRANSFER.card.documents_exploitation_del' = 'c_DeviceTransfer.cf_DeleteDocumentExploitation',
  'DEVICE_TRANSFER.card.psi_add' = 'c_DeviceTransfer.cf_AddPSI',
  'DEVICE_TRANSFER.card.psi_del' = 'c_DeviceTransfer.cf_DelPSI',
  'DEVICE_TRANSFER.card.psi_back' = 'c_DeviceTransfer.cf_GetBackPSI',
  // УПД
  'DEVICE_MODEM.card' = 'c_DeviceModem',
  'DEVICE_MODEM.card.history' = 'c_DeviceModem.ct_History',
  'DEVICE_MODEM.card.documents' = 'c_DeviceModem.ct_Documents',
  'DEVICE_MODEM.card.config' = 'c_DeviceModem.ct_Config',
  'DEVICE_MODEM.card.warranty' = 'c_DeviceModem.ct_Warranty',
  'DEVICE_MODEM.card.dispatching' = 'c_DeviceModem.ct_Dispatching',
  'DEVICE_MODEM.card.psi' = 'c_DeviceModem.ct_PSIjournal',
  'DEVICE_MODEM.card_add' = 'c_DeviceModem.cf_add',
  'DEVICE_MODEM.card_edit' = 'c_DeviceModem.cf_edit',
  'DEVICE_MODEM.card_del' = 'c_DeviceModem.cf_del',
  'DEVICE_MODEM.card_to_service' = 'c_DeviceModem.cf_StatusToService',
  'DEVICE_MODEM.card_to_archive' = 'c_DeviceModem.cf_StatusToArchive',
  'DEVICE_MODEM.card_from_archive' = 'c_DeviceModem.cf_StatusFromArchive',
  'DEVICE_MODEM.connect_to_measure_object' = 'c_DeviceModem.cf_ConnectToMeasureObject',
  'DEVICE_MODEM.disconnect_from_measure_object' = 'c_DeviceModem.cf_DisconnectFromMeasureObject',
  'DEVICE_MODEM.del_connect_to_measure_object' = 'c_DeviceModem.cf_DeleteConnectToMeasureObject',
  'DEVICE_MODEM.check_connect' = 'c_DeviceModem.cf_CheckConnectionModem',
  'DEVICE_MODEM.write_modem_params' = 'c_DeviceModem.cf_WriteModemParam',
  'DEVICE_MODEM.read_modem_tree' = 'c_DeviceModem.cf_ReadModemTree',
  'DEVICE_MODEM.get_instant_data' = 'c_DeviceModem.cf_GetInstantMeterDataModem',
  'DEVICE_MODEM.restart_modem' = 'c_DeviceModem.cf_RestartModem',
  'DEVICE_MODEM.write_modem_tree' = 'c_DeviceModem.cf_WriteModemTree',
  'DEVICE_MODEM.reread_instant_data' = 'c_DeviceModem.cf_RereadMeterDataModem',
  'DEVICE_MODEM.generate_passport' = 'c_DeviceModem.cf_GeneratePassportModem',
  'DEVICE_MODEM.generate_ppi' = 'c_DeviceModem.cf_GeneratePPIModem',
  'DEVICE_MODEM.card.warranty_add' = 'c_DeviceModem.cf_AddWarranty',
  'DEVICE_MODEM.card.warranty_edit' = 'c_DeviceModem.cf_EditWarranty',
  'DEVICE_MODEM.card.warranty_del' = 'c_DeviceModem.cf_DeleteWarranty',
  'DEVICE_MODEM.card.documents_add' = 'c_DeviceModem.cf_AddDocument',
  'DEVICE_MODEM.card.documents_edit' = 'c_DeviceModem.cf_EditDocument',
  'DEVICE_MODEM.card.documents_del' = 'c_DeviceModem.cf_DeleteDocument',
  'DEVICE_MODEM.card.documents_verification_add' = 'c_DeviceModem.cf_AddDocumentVerification',
  'DEVICE_MODEM.card.documents_verification_edit' = 'c_DeviceModem.cf_EditDocumentVerification',
  'DEVICE_MODEM.card.documents_verification_del' = 'c_DeviceModem.cf_DeleteDocumentVerification',
  'DEVICE_MODEM.card.documents_exploitation_add' = 'c_DeviceModem.cf_AddDocumentExploitation',
  'DEVICE_MODEM.card.documents_exploitation_edit' = 'c_DeviceModem.cf_EditDocumentExploitation',
  'DEVICE_MODEM.card.documents_exploitation_del' = 'c_DeviceModem.cf_DeleteDocumentExploitation',
  'DEVICE_MODEM.card.psi_add' = 'c_DeviceModem.cf_AddPSI',
  'DEVICE_MODEM.card.psi_del' = 'c_DeviceModem.cf_DelPSI',
  'DEVICE_MODEM.card.psi_back' = 'c_DeviceModem.cf_GetBackPSI',
  // ПУ
  'DEVICE_METER.card' = 'c_DeviceMeter',
  'DEVICE_METER.card.history' = 'c_DeviceMeter.ct_History',
  'DEVICE_METER.card.documents' = 'c_DeviceMeter.ct_Documents',
  'DEVICE_METER.card.warranty' = 'c_DeviceMeter.ct_Warranty',
  'DEVICE_METER.card.dispatching' = 'c_DeviceMeter.ct_Dispatching',
  'DEVICE_METER.card_add' = 'c_DeviceMeter.cf_add',
  'DEVICE_METER.card_edit' = 'c_DeviceMeter.cf_edit',
  'DEVICE_METER.card_del' = 'c_DeviceMeter.cf_del',
  'DEVICE_METER.card_to_service' = 'c_DeviceMeter.cf_StatusToService',
  'DEVICE_METER.card_to_work' = 'c_DeviceMeter.cf_StatusToWork',
  'DEVICE_METER.card_to_archive' = 'c_DeviceMeter.cf_StatusToArchive',
  'DEVICE_METER.card_from_archive' = 'c_DeviceMeter.cf_StatusFromArchive',
  'DEVICE_METER.connect_to_usd' = 'c_DeviceMeter.cf_ConnectToUSD',
  'DEVICE_METER.disconnect_from_usd' = 'c_DeviceMeter.cf_DisconnectFromUSD',
  'DEVICE_METER.del_connect_to_usd' = 'c_DeviceMeter.cf_DeleteConnectToUSD',
  'DEVICE_METER.update_archive_params' = 'c_DeviceMeter.cf_RereadMeterDataPU',
  'DEVICE_METER.card.warranty_add' = 'c_DeviceMeter.cf_AddWarranty',
  'DEVICE_METER.card.warranty_edit' = 'c_DeviceMeter.cf_EditWarranty',
  'DEVICE_METER.card.warranty_del' = 'c_DeviceMeter.cf_DeleteWarranty',
  'DEVICE_METER.card.documents_add' = 'c_DeviceMeter.cf_AddDocument',
  'DEVICE_METER.card.documents_edit' = 'c_DeviceMeter.cf_EditDocument',
  'DEVICE_METER.card.documents_del' = 'c_DeviceMeter.cf_DeleteDocument',
  'DEVICE_METER.card.documents_verification_add' = 'c_DeviceMeter.cf_AddDocumentVerification',
  'DEVICE_METER.card.documents_verification_edit' = 'c_DeviceMeter.cf_EditDocumentVerification',
  'DEVICE_METER.card.documents_verification_del' = 'c_DeviceMeter.cf_DeleteDocumentVerification',
  'DEVICE_METER.card.documents_exploitation_add' = 'c_DeviceMeter.cf_AddDocumentExploitation',
  'DEVICE_METER.card.documents_exploitation_edit' = 'c_DeviceMeter.cf_EditDocumentExploitation',
  'DEVICE_METER.card.documents_exploitation_del' = 'c_DeviceMeter.cf_DeleteDocumentExploitation',
  'DEVICE_METER.card.documents_staging_add' = 'c_DeviceMeter.cf_AddDocumentStaging',
  'DEVICE_METER.card.documents_staging_edit' = 'c_DeviceMeter.cf_EditDocumentStaging',
  'DEVICE_METER.card.documents_staging_del' = 'c_DeviceMeter.cf_DeleteDocumentStaging',
  // ИУ
  'DEVICE_MEASURE.card' = 'c_DeviceMeasure',
  'DEVICE_MEASURE.card.history' = 'c_DeviceMeasure.ct_History',
  'DEVICE_MEASURE.card.documents' = 'c_DeviceMeasure.ct_Documents',
  'DEVICE_MEASURE.card.warranty' = 'c_DeviceMeasure.ct_Warranty',
  'DEVICE_MEASURE.card_add' = 'c_DeviceMeasure.cf_add',
  'DEVICE_MEASURE.card_edit' = 'c_DeviceMeasure.cf_edit',
  'DEVICE_MEASURE.card_del' = 'c_DeviceMeasure.cf_del',
  'DEVICE_MEASURE.card_to_service' = 'c_DeviceMeasure.cf_StatusToService',
  'DEVICE_MEASURE.card_to_archive' = 'c_DeviceMeasure.cf_StatusToArchive',
  'DEVICE_MEASURE.card_from_archive' = 'c_DeviceMeasure.cf_StatusFromArchive',
  'DEVICE_MEASURE.connect_to_measure_point' = 'c_DeviceMeasure.cf_ConnectToMeasurePoint',
  'DEVICE_MEASURE.disconnect_from_measure_point' = 'c_DeviceMeasure.cf_DisconnectFromMeasurePoint',
  'DEVICE_MEASURE.del_connect_to_measure_point' = 'c_DeviceMeasure.cf_DeleteConnectToMeasurePoint',
  'DEVICE_MEASURE.card.warranty_add' = 'c_DeviceMeasure.cf_AddWarranty',
  'DEVICE_MEASURE.card.warranty_edit' = 'c_DeviceMeasure.cf_EditWarranty',
  'DEVICE_MEASURE.card.warranty_del' = 'c_DeviceMeasure.cf_DeleteWarranty',
  'DEVICE_MEASURE.card.documents_add' = 'c_DeviceMeasure.cf_AddDocument',
  'DEVICE_MEASURE.card.documents_edit' = 'c_DeviceMeasure.cf_EditDocument',
  'DEVICE_MEASURE.card.documents_del' = 'c_DeviceMeasure.cf_DeleteDocument',
  'DEVICE_MEASURE.card.documents_verification_add' = 'c_DeviceMeasure.cf_AddDocumentVerification',
  'DEVICE_MEASURE.card.documents_verification_edit' = 'c_DeviceMeasure.cf_EditDocumentVerification',
  'DEVICE_MEASURE.card.documents_verification_del' = 'c_DeviceMeasure.cf_DeleteDocumentVerification',
  'DEVICE_MEASURE.card.documents_exploitation_add' = 'c_DeviceMeasure.cf_AddDocumentExploitation',
  'DEVICE_MEASURE.card.documents_exploitation_edit' = 'c_DeviceMeasure.cf_EditDocumentExploitation',
  'DEVICE_MEASURE.card.documents_exploitation_del' = 'c_DeviceMeasure.cf_DeleteDocumentExploitation',
  // ТУ
  'MEASURE_POINT' = 'g_MeasurePoints',
  'MEASURE_POINT.download_daily_report' = 'g_MeasurePoints.gf_DownloadMeasureReport',
  'MEASURE_POINT.download_daily_with_calc_report' = 'g_MeasurePoints.gf_ReportDailyRecalcCreate',
  'MEASURE_POINT.download_hourly_report' = 'g_MeasurePoints.gf_DownloadHReport',
  'MEASURE_POINT.download_power_profile_report' = 'g_MeasurePoints.gf_DownloadPwrPReport',
  'MEASURE_POINT.set_report_tmpl_default' = 'g_MeasurePoints.gf_ReportTemplateUnconnect',
  'MEASURE_POINT.set_report_tmpl' = 'g_MeasurePoints.gf_ReportTemplateConnect',
  'MEASURE_POINT.download_report_tg' = 'g_MeasurePoints.gf_DownloadReportTG',
  'MEASURE_POINT.card' = 'c_MeasurePoint',
  'MEASURE_POINT.card.history' = 'c_MeasurePoint.ct_History',
  'MEASURE_POINT.card.report' = 'c_MeasurePoint.ct_MeasureReport',
  'MEASURE_POINT.card.dispatching' = 'c_MeasurePoint.ct_Dispatching',
  'MEASURE_POINT.card.temperature_graphs' = 'c_MeasurePoint.ct_TemplateTemperatureGraphs',
  'MEASURE_POINT.card.temperature_graphs.connect' = 'c_MeasurePoint.cf_ConnectTTG',
  'MEASURE_POINT.card.temperature_graphs.delete' = 'c_MeasurePoint.cf_DelTTG',
  'MEASURE_POINT.card.temperature_graphs.end' = 'c_MeasurePoint.cf_DisconnectTTG',
  'MEASURE_POINT.card.subconsumer' = 'c_MeasurePoint.ct_SubSubscriber',
  'MEASURE_POINT.card.subconsumer.add' = 'c_MeasurePoint.cf_ConnectSubSubscribers',
  'MEASURE_POINT.card.subconsumer.del' = 'c_MeasurePoint.cf_DelSubSubscribers',
  'MEASURE_POINT.card.subconsumer.disconnect' = 'c_MeasurePoint.cf_DisconnectSubSubscriber',
  'MEASURE_POINT.card_add' = 'c_MeasurePoint.cf_add',
  'MEASURE_POINT.card_edit' = 'c_MeasurePoint.cf_edit',
  'MEASURE_POINT.card_del' = 'c_MeasurePoint.cf_del',
  'MEASURE_POINT.card_to_archive' = 'c_MeasurePoint.cf_StatusToArchive',
  'MEASURE_POINT.card_from_archive' = 'c_MeasurePoint.cf_StatusFromArchive',
  'MEASURE_POINT.connect_to_device_meter' = 'c_MeasurePoint.cf_ConnectToDeviceMeter',
  'MEASURE_POINT.disconnect_from_device_meter' = 'c_MeasurePoint.cf_DisconnectFromDeviceMeter',
  'MEASURE_POINT.del_connect_to_device_meter' = 'c_MeasurePoint.cf_DeleteConnectToDeviceMeter',
  'MEASURE_POINT.open_instant_journal' = 'c_MeasurePoint.cf_OpenInstantJournal',
  'MEASURE_POINT.open_archive_journal' = 'c_MeasurePoint.cf_OpenArchiveJournal',
  'MEASURE_POINT.open_manual_input_journal' = 'c_MeasurePoint.cf_OpenManualInputJournal',
  'MEASURE_POINT.edit_manual_input_journal' = 'c_MeasurePoint.cf_EditManualInputJournal',
  'MEASURE_POINT.get_instant_meter_data' = 'c_MeasurePoint.cf_GetInstantMeterData',
  'MEASURE_POINT.reread_instant_data' = 'c_MeasurePoint.cf_RereadMeterDataTU',
  'MEASURE_POINT.regime_map' = 'c_MeasurePoint.ct_RegimeMap',
  'MEASURE_POINT.connect_regime_map' = 'c_MeasurePoint.cf_ConnectRegimeMap',
  'MEASURE_POINT.delete_regime_map' = 'c_MeasurePoint.cf_DeleteRegimeMap',
  'MEASURE_POINT.disconnect_regime_map' = 'c_MeasurePoint.cf_DisconnectRegimeMap',
  'MEASURE_POINT.open_template_regime_map' = 'c_MeasurePoint.cf_OpenTemplateOfRegimeMap',
  'MEASURE_POINT.card.set_report_tmpl_default' = 'c_MeasurePoint.cf_ReportTemplateUnconnect',
  'MEASURE_POINT.card.set_report_tmpl' = 'c_MeasurePoint.cf_ReportTemplateConnect',
  // Измерительное оборудование
  'DEVICE' = 'g_Devices',
  'DEVICE.DOWNLOAD_REPORT' = 'g_Devices.gf_DownloadReports',
  'DEVICE.usd_to_ou.card' = 'c_TransferToObjectConnectionHist',
  'DEVICE.usd_to_ou.card_edit' = 'c_TransferToObjectConnectionHist.cf_edit',
  'DEVICE.pu_to_usd.card' = 'c_MeterToTransferConnectionHist',
  'DEVICE.pu_to_usd.card_edit' = 'c_MeterToTransferConnectionHist.cf_edit',
  'DEVICE.tu_to_pu.card' = 'c_PointToMeterConnectionHist',
  'DEVICE.tu_to_pu.card_edit' = 'c_PointToMeterConnectionHist.cf_edit',
  'DEVICE.iu_to_tu.card' = 'c_MeasureToPointConnectionHist',
  'DEVICE.iu_to_tu.card_edit' = 'c_MeasureToPointConnectionHist.cf_edit',
  'DEVICE.tree_uspd_to_ou.card_edit' = 'c_DeviceTransfer.cf_EditConnectionToMeasureObject', // Открыть карточку подключения УСПД к ОУ из дерева устройств
  'DEVICE.tree_upd_to_ou.card_edit' = 'c_DeviceModem.cf_EditConnectionToMeasureObject', // Открыть карточку подключения УПД к ОУ из дерева устройств
  'DEVICE.tree_pu_to_usd.card_edit' = 'c_DeviceMeter.cf_EditConnectionToUSD', // Открыть карточку подключения ПУ к УСД из дерева устройств
  'DEVICE.tree_iu_to_tu.card_edit' = 'c_DeviceMeasure.cf_EditConnectionToMeasurePoint', // Открыть карточку подключения ИУ к ТУ из дерева устройств
  'DEVICE.tree_tu_to_pu.card_edit' = 'c_MeasurePoint.cf_EditConnectionToDeviceMeter', // Открыть карточку подключения ТУ к ПУ из дерева устройств
  'DEVICE.uploadDeviceList' = 'g_Devices.gf_UploadDeviceList',
  // Инженерное оборудование
  'ENGINEER_DEVICE.card' = 'c_EngineerEquip',
  'ENGINEER_DEVICE.card_add' = 'c_EngineerEquip.cf_add',
  'ENGINEER_DEVICE.card_edit' = 'c_EngineerEquip.cf_edit',
  'ENGINEER_DEVICE.card_del' = 'c_EngineerEquip.cf_del',
  'ENGINEER_DEVICE.transferToStartUp' = 'c_EngineerEquip.cf_Commissioning',
  'ENGINEER_DEVICE.transferToWorking' = 'c_EngineerEquip.cf_Exploitation',
  'ENGINEER_DEVICE.transferToMaintenance' = 'c_EngineerEquip.cf_Repair',
  'ENGINEER_DEVICE.transferToInactive' = 'c_EngineerEquip.cf_Mothball',
  'ENGINEER_DEVICE.transferToDismantled' = 'c_EngineerEquip.cf_Dismantle',
  'ENGINEER_DEVICE.connect' = 'c_EngineerEquip.cf_Connect',
  'ENGINEER_DEVICE.disconnect' = 'c_EngineerEquip.cf_Disconnect',
  'ENGINEER_DEVICE.card.documents' = 'c_EngineerEquip.ct_documents',
  'ENGINEER_DEVICE.card.documents_add' = 'c_EngineerEquip.cf_AddDocument',
  'ENGINEER_DEVICE.card.documents_edit' = 'c_EngineerEquip.cf_EditDocument',
  'ENGINEER_DEVICE.card.documents_del' = 'c_EngineerEquip.cf_DeleteDocument',
  'ENGINEER_DEVICE.card.nur' = 'c_EngineerEquip.ct_NormativelySpecific',
  'ENGINEER_DEVICE.card.nur.add' = 'c_EngineerEquip.cf_AddIndividualNormative',
  'ENGINEER_DEVICE.card.nur.edit' = 'c_EngineerEquip.cf_EditIndividualNormative',
  'ENGINEER_DEVICE.card.nur.delete' = 'c_EngineerEquip.cf_DeleteIndividualNormative',
  'ENGINEER_DEVICE.card.nur.manual_input' = 'c_EngineerEquip.cf_ManualInputNormative',
  'ENGINEER_DEVICE.card.nur.calc' = 'c_EngineerEquip.cf_CalculateIndividualNormative',
  // Метеостанции
  'METEOSTATIONS' = 'g_MeteoStations',
  'METEOSTATIONS.IMPORT_FILE_OUTSIDE_TEMP' = 'g_MeteoStations.gf_ImportFileOfExtTemp',
  'METEOSTATIONS.DELETE_CONNECTION_MSMO' = 'g_MeteoStations.gf_DeleteConnectionMSMO',
  'METEOSTATIONS.CARD' = 'c_Meteostation',
  'METEOSTATIONS.CARD.ADD' = 'g_MeteoStations.gf_AddMeteoStation',
  'METEOSTATIONS.CARD.EDIT' = 'c_Meteostation.cf_updateMeteostation',
  'METEOSTATIONS.CARD.ADD_JOURNAL' = 'c_Meteostation.cf_addNoteExternalTemperatureJournal',
  'METEOSTATIONS.CARD.EDIT_JOURNAL' = 'c_Meteostation.cf_editCardExternalTemperatureJournal',
  'METEOSTATIONS.CARD.IMPORT_FILE_OUTSIDE_TEMP' = 'c_Meteostation.cf_importFileExternalTemperatureJournal',
  // Договора поставки ресурсов
  'CONTRACTS' = 'm_Contracts',
  'SUPPLY_CONTRACTS' = 'g_DeliveryContracts',
  'SUPPLY_CONTRACT.card' = 'c_DeliveryContract',
  'SUPPLY_CONTRACT.card_add' = 'g_DeliveryContracts.gf_add',
  'SUPPLY_CONTRACT.card_edit' = 'c_DeliveryContract.cf_edit',
  'SUPPLY_CONTRACT.card_del' = 'c_DeliveryContract.cf_del',
  'SUPPLY_CONTRACT.card.addition' = 'c_DeliveryContractAdditional',
  'SUPPLY_CONTRACT.card.addition_add' = 'c_DeliveryContract.cf_additional_add',
  'SUPPLY_CONTRACT.card.addition_edit' = 'c_DeliveryContractAdditional.cf_edit',
  'SUPPLY_CONTRACT.card.addition_del' = 'c_DeliveryContractAdditional.cf_del',
  'SUPPLY_CONTRACT.card.addition_add_tu' = 'c_DeliveryContractAdditional.cf_add_tu',
  'SUPPLY_CONTRACT.card.addition_del_tu' = 'c_DeliveryContractAdditional.cf_del_tu',
  'SUPPLY_CONTRACT.card.addition_add_param' = 'c_DeliveryContractAdditional.cf_add_param',
  'SUPPLY_CONTRACT.card.addition_edit_param' = 'c_DeliveryContractAdditional.cf_edit_param',
  'SUPPLY_CONTRACT.card.addition_del_param' = 'c_DeliveryContractAdditional.cf_del_param',
  'SUPPLY_CONTRACT.card.addition_add_reg_param' = 'c_DeliveryContractAdditional.cf_add_reg_param',
  'SUPPLY_CONTRACT.card.addition_edit_reg_param' = 'c_DeliveryContractAdditional.cf_edit_reg_param',
  'SUPPLY_CONTRACT.card.addition_del_reg_param' = 'c_DeliveryContractAdditional.cf_del_reg_param',
  'SUPPLY_CONTRACT.card.addition_add_fact_param' = 'c_DeliveryContractAdditional.cf_add_fact_param',
  'SUPPLY_CONTRACT.card.addition_del_fact_param' = 'c_DeliveryContractAdditional.cf_del_fact_param',
  'SUPPLY_CONTRACT.card.addition_add_year_regime' = 'c_DeliveryContractAdditional.cf_add_year_regime',
  'SUPPLY_CONTRACT.card.addition_del_year_regime' = 'c_DeliveryContractAdditional.cf_del_year_regime',
  'SUPPLY_CONTRACT.card.addition_edit_mp_card' = 'c_DeliveryContractAdditional.cf_edit_mp_card',
  // Мнемосхемы
  'SETTINGS' = 'm_Settings',
  'MNEMOSCHEME' = 'g_MnemonicScheme',
  'MNEMOSCHEME.card' = 'c_MnemonicScheme',
  'MNEMOSCHEME.card_add' = 'c_MnemonicScheme.cf_add',
  'MNEMOSCHEME.card_edit' = 'c_MnemonicScheme.cf_edit',
  'MNEMOSCHEME.card_del' = 'c_MnemonicScheme.cf_del',
  'MNEMOSCHEME.card_rename' = 'c_MnemonicScheme.cf_rename',
  'MNEMOSCHEME.card_copy' = 'c_MnemonicScheme.cf_copy',
  // Режимные карты
  'REGIME_MAP' = 'g_RegimeMap',
  // Шаблоны РК
  'REGIME_MAP.card' = 'c_TemplateOfRegimeMap',
  'REGIME_MAP.card_add' = 'c_TemplateOfRegimeMap.cf_add',
  'REGIME_MAP.card_edit' = 'c_TemplateOfRegimeMap.cf_edit',
  'REGIME_MAP.card_del' = 'c_TemplateOfRegimeMap.cf_del',
  'REGIME_MAP.to_archive' = 'c_TemplateOfRegimeMap.cf_ToArchive',
  'REGIME_MAP.from_archive' = 'c_TemplateOfRegimeMap.cf_FromArchive',
  'REGIME_MAP.version_add' = 'c_TemplateOfRegimeMap.cf_AddVersion',
  'REGIME_MAP.version_edit' = 'c_TemplateOfRegimeMap.cf_EditVersion',
  'REGIME_MAP.version_del' = 'c_TemplateOfRegimeMap.cf_DelVersion',
  'REGIME_MAP.version_start' = 'c_TemplateOfRegimeMap.cf_StartVersion',
  'REGIME_MAP.version_end' = 'c_TemplateOfRegimeMap.cf_FinishVersion',
  'REGIME_MAP.version_param_add' = 'c_TemplateOfRegimeMap.cf_AddDimensionParam',
  'REGIME_MAP.version_param_del' = 'c_TemplateOfRegimeMap.cf_DelDimensionParam',
  'REGIME_MAP.apply_to_mp' = 'c_TemplateOfRegimeMap.cf_MassConnectRegimeMap',
  // Температурные графики
  'TEMPERATURE_GRAPHS' = 'g_TemperatureGraphs',
  'TEMPERATURE_GRAPHS.CARD' = 'c_TemplateTemperatureGraphs',
  'TEMPERATURE_GRAPHS.CARD.ADD' = 'c_TemplateTemperatureGraphs.cf_AddTTG',
  'TEMPERATURE_GRAPHS.CARD.COPY' = 'c_TemplateTemperatureGraphs.cf_AddCopyTTG',
  'TEMPERATURE_GRAPHS.CARD.EDIT' = 'c_TemplateTemperatureGraphs.cf_EditTTG',
  'TEMPERATURE_GRAPHS.CARD.ARCHIVE' = 'c_TemplateTemperatureGraphs.cf_ArchiveTTG',
  'TEMPERATURE_GRAPHS.CARD.REARCHIVE' = 'c_TemplateTemperatureGraphs.cf_RearchiveTTG',
  'TEMPERATURE_GRAPHS.CARD.EDIT_SYS' = 'c_TemplateTemperatureGraphs.cf_EditSysTTG',
  'TEMPERATURE_GRAPHS.CARD.DELETE' = 'c_TemplateTemperatureGraphs.cf_DelTTG',
  'TEMPERATURE_GRAPHS.CARD.APPLY_TU_LIST' = 'c_TemplateTemperatureGraphs.cf_MassTTGtoTU',
  'TEMPERATURE_GRAPHS.CARD.ADD_PARAM' = 'c_TemplateTemperatureGraphs.cf_AddParamTTG',
  'TEMPERATURE_GRAPHS.CARD.DELETE_PARAM' = 'c_TemplateTemperatureGraphs.cf_DelParamTTG',
  // Шаблоны ВП
  'TEMPLATE_REPORT' = 'g_MeasureReportTmpl',
  'TEMPLATE_REPORT.card' = 'c_MeasureReportTmpl',
  'TEMPLATE_REPORT.card_add' = 'g_MeasureReportTmpl.gf_Add',
  'TEMPLATE_REPORT.card_edit' = 'c_MeasureReportTmpl.cf_edit',
  'TEMPLATE_REPORT.card_system_edit' = 'c_MeasureReportTmpl.cf_System_edit',
  'TEMPLATE_REPORT.card_del' = 'c_MeasureReportTmpl.cf_del',
  'TEMPLATE_REPORT.card_file_download' = 'c_MeasureReportTmpl.cf_FileDownload',
  'TEMPLATE_REPORT.card_file_upload' = 'c_MeasureReportTmpl.cf_FileUpload',
  'TEMPLATE_REPORT.card_system_file_upload' = 'c_MeasureReportTmpl.cf_SystemFileUpload',
  'TEMPLATE_REPORT.card_activate' = 'c_MeasureReportTmpl.cf_Activate',
  'TEMPLATE_REPORT.card_deactivate' = 'c_MeasureReportTmpl.cf_Deactivate',
  'TEMPLATE_REPORT.card.file_history' = 'c_MeasureReportTmpl.cf_FileHistoryView',
  'TEMPLATE_REPORT.card.file_history_activate' = 'c_MeasureReportTmpl.cf_FileActivate',
  'TEMPLATE_REPORT.card.file_history_system_activate' = 'c_MeasureReportTmpl.cf_SystemFileActivate',
  'TEMPLATE_REPORT.card.file_history_del' = 'c_MeasureReportTmpl.cf_FileDelete',
  'TEMPLATE_REPORT.card.file_history_system_del' = 'c_MeasureReportTmpl.cf_SystemFileDelete',
  // Карточка ВП
  'MEASURE_REPORT.card' = 'c_MeasureReport',
  'MEASURE_REPORT.download_daily_report' = 'c_MeasureReport.cf_ReportDailyCreate',
  'MEASURE_REPORT.download_daily_report_with_calc' = 'c_MeasureReport.cf_ReportDailyRecalcCreate',
  'MEASURE_REPORT.download_hourly_report' = 'c_MeasureReport.cf_ReportHourlyCreate',
  'MEASURE_REPORT.download_power_profile_report' = 'c_MeasureReport.cf_ReportPwrProfileCreate',
  'MEASURE_REPORT.card.report_version_activate' = 'c_MeasureReport.cf_ReportVersionActivate',
  'MEASURE_REPORT.card.report_version_debug_download' = 'c_MeasureReport.cf_ReportDailyRecalcDebugDownload',
  'MEASURE_REPORT.card.report_version_approve_create' = 'c_MeasureReport.cf_ReportVersionApprove',
  'MEASURE_REPORT.card.report_version_approve_edit' = 'c_MeasureReport.cf_ReportVersionApproveUpdate',
  'MEASURE_REPORT.card.report_version_approve_revoke' = 'c_MeasureReport.cf_ReportVersionApproveRevoke',
  // Отопительные сезоны
  'REGIME_SEASON.card' = 'c_MeasureObject.ct_HeatSeasons',
  'REGIME_SEASON.card_add' = 'c_MeasureObject.cf_AddSeason',
  'REGIME_SEASON.card_edit' = 'c_MeasureObject.cf_EditSeason',
  'REGIME_SEASON.del' = 'c_MeasureObject.cf_DeleteSeason',
  // Журнал режимов работы оборудования
  'WORK_JOURNAL.card' = 'c_MeasureObject.ct_WorkJournal',
  'WORK_JOURNAL.card_edit' = 'c_MeasureObject.cf_EditWorkJournal',
  'WORK_JOURNAL.version_add' = 'c_MeasureObject.cf_AddWorkJournalVersion',
  'WORK_JOURNAL.columns_edit' = 'c_MeasureObject.cf_EditWorkJournalColumns',
  'WORK_JOURNAL.params_edit' = 'c_MeasureObject.cf_EditWorkJournalData',
  // Управление инфрааструктурой, техническая страница
  'ADMIN.WEB_PROXY' = 'g_WebProxy',
  'ADMIN.WEB_PROXY.CARD' = 'c_WebProxy',
  'ADMIN.WEB_PROXY.CARD.JOURNAL' = 'c_WebProxy.ct_Journal',
  'ADMIN.WEB_PROXY.CARD.ADD' = 'c_WebProxy.cf_add',
  'ADMIN.WEB_PROXY.CARD.EDIT' = 'c_WebProxy.cf_edit',
  'ADMIN.WEB_PROXY.CARD.DELETE' = 'c_WebProxy.cf_delete',
  'ADMIN.WEB_PROXY.CARD.ADD_ROLE' = 'c_WebProxy.cf_add_role',
  'ADMIN.WEB_PROXY.CARD.DELETE_ROLE' = 'c_WebProxy.cf_delete_role',
  // Формулы. Расчеты и досчеты
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY' = 'g_FormulasRegister',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.LIST' = 'g_FormulasRegister.gf_viewFormulasList',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.VIEW' = 'g_FormulasRegister.gf_viewFormulaCard',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.ADD' = 'g_FormulasRegister.gf_createFormula',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.EDIT' = 'c_Formula.cf_editFormula',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.CARD.DELETE' = 'c_Formula.cf_deleteFormula',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.CARD.ACTIVATE' = 'c_Formula.cf_activateFormula',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.CARD.ARCHIVE' = 'c_Formula.cf_archiveFormula',
  'ENERGY_RESOURCES.SETTINGS.FORMULAS_REGISTRY.CARD.RESTORE' = 'c_Formula.cf_recoverFormula',
  // Диспетчеризация. ПС
  'DISPATCHER' = 'm_Dispatcher',
  'DISPATCHER.INCIDENTS' = 'g_Problems',
  'DISPATCHER.INCIDENTS.CARD' = 'c_Problem',
  'DISPATCHER.INCIDENTS.CLOSE' = 'c_Problem.cf_Close',
  // ТОиР
  'MAINTAINENCE' = 'm_Maintainence',
  'MAINTAINENCE.CHECKUP_PLANS' = 'g_CheckupPlans',
  'MAINTAINENCE.CHECKUP_PLANS.CARD' = 'c_CheckupPlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.ADD' = 'c_CheckupPlan.cf_AddPlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.EDIT' = 'c_CheckupPlan.cf_EditPlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.DELETE' = 'c_CheckupPlan.cf_DeletePlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.REVIEW' = 'c_CheckupPlan.cf_ReviewPlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.APPROVE' = 'c_CheckupPlan.cf_ApprovePlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.CANCEL' = 'c_CheckupPlan.cf_CancelPlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.ARCHIVE' = 'c_CheckupPlan.cf_ArchivePlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.RESTORE' = 'c_CheckupPlan.cf_RestorePlan',
  'MAINTAINENCE.CHECKUP_PLANS.CARD.CONFIRM' = 'c_CheckupPlan.cf_ConfirmPlan',
  'MAINTAINENCE.CHECKUP_ACTION.CARD' = 'c_CheckupAction',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.ADD' = 'c_CheckupAction.cf_AddAction',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.EDIT' = 'c_CheckupAction.cf_EditAction',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.DELETE' = 'c_CheckupAction.cf_DeleteAction',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.DOWNLOAD_ALL' = 'c_CheckupAction.cf_AllFilesDownload',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.HYDROTEST_TEMPLATE_DOWNLOAD' = 'c_CheckupAction.cf_HydrotestTemplateDownload',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.INSPECTION_TEMPLATE_DOWNLOAD' = 'c_CheckupAction.cf_CheckupTemplateDownload',
  'MAINTAINENCE.CHECKUP_ACTION.CARD.METER_INSPECTION_TEMPLATE_DOWNLOAD' = 'c_CheckupAction.cf_PUCheckupTemplateDownload',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD' = 'c_CheckupActionJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.ADD' = 'c_CheckupActionJob.cf_AddJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.ADD_UNPLANNED' = 'c_CheckupActionJob.cf_AddUnplannedJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.EDIT' = 'c_CheckupActionJob.cf_EditJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.DELETE' = 'c_CheckupActionJob.cf_DeleteJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.DELETE_UNPLANNED' = 'c_CheckupActionJob.cf_DeleteUnplannedJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.REPORT' = 'c_CheckupActionJob.cf_ReportJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.REPORT_DELETE' = 'c_CheckupActionJob.cf_DeleteReportJob',
  'MAINTAINENCE.CHECKUP_ACTION_JOB.CARD.CHANGE_CREW' = 'c_CheckupActionJob.cf_ChangeMaintainenceCrewJob',
  'MAINTAINENCE.MAINTAINENCE_PLANS' = 'g_MaintainencePlans',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD' = 'c_MaintainencePlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.ADD' = 'c_MaintainencePlan.cf_AddPlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.EDIT' = 'c_MaintainencePlan.cf_EditPlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.DELETE' = 'c_MaintainencePlan.cf_DeletePlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.REVIEW' = 'c_MaintainencePlan.cf_ReviewPlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.APPROVE' = 'c_MaintainencePlan.cf_ApprovePlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.CANCEL' = 'c_MaintainencePlan.cf_CancelPlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.ARCHIVE' = 'c_MaintainencePlan.cf_ArchivePlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.RESTORE' = 'c_MaintainencePlan.cf_RestorePlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.CONFIRM' = 'c_MaintainencePlan.cf_ConfirmPlan',
  'MAINTAINENCE.MAINTAINENCE_PLANS.CARD.CREATE_SUMMARY_PLAN' = 'c_MaintainencePlan.CreateSummaryPlan',
  'MAINTAINENCE.MAINTAINENCE_ACTION.CARD' = 'c_MaintainenceAction',
  'MAINTAINENCE.MAINTAINENCE_ACTION.CARD.ADD' = 'c_MaintainenceAction.cf_AddAction',
  'MAINTAINENCE.MAINTAINENCE_ACTION.CARD.EDIT' = 'c_MaintainenceAction.cf_EditAction',
  'MAINTAINENCE.MAINTAINENCE_ACTION.CARD.DELETE' = 'c_MaintainenceAction.cf_DeleteAction',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD' = 'c_MaintainenceActionJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.ADD' = 'c_MaintainenceActionJob.cf_AddJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.ADD_UNPLANNED' = 'c_MaintainenceActionJob.cf_AddUnplannedJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.EDIT' = 'c_MaintainenceActionJob.cf_EditJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.DELETE' = 'c_MaintainenceActionJob.cf_DeleteJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.DELETE_UNPLANNED' = 'c_MaintainenceActionJob.cf_DeleteUnplannedJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.REPORT' = 'c_MaintainenceActionJob.cf_ReportJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.REPORT_DELETE' = 'c_MaintainenceActionJob.cf_DeleteReportJob',
  'MAINTAINENCE.MAINTAINENCE_ACTION_JOB.CARD.CHANGE_CREW' = 'c_MaintainenceActionJob.cf_ChangeMaintainenceCrewJob',
  'MAINTAINENCE.MAINTAINENCE_CREW' = 'g_MaintainenceCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD' = 'c_MaintainenceCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.ADD' = 'c_MaintainenceCrew.cf_AddCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.EDIT' = 'c_MaintainenceCrew.cf_EditCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.DELETE' = 'c_MaintainenceCrew.cf_DeleteCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.ARCHIVE' = 'c_MaintainenceCrew.cf_ArchiveCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.RESTORE' = 'c_MaintainenceCrew.cf_RestoreCrew',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.ADD_MEMBER' = 'c_MaintainenceCrew.cf_AddMember',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.DELETE_MEMBER' = 'c_MaintainenceCrew.cf_DeleteMember',
  'MAINTAINENCE.MAINTAINENCE_CREW.CARD.SET_LEADER' = 'c_MaintainenceCrew.cf_PromoteMember',
  'MAINTAINENCE.WINTER_PLANS' = 'g_AWPeriodPlans',
  'MAINTAINENCE.WINTER_PLANS.CARD' = 'c_AWPeriodPlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.ADD' = 'c_AWPeriodPlan.cf_AddPlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.EDIT' = 'c_AWPeriodPlan.cf_EditPlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.DELETE' = 'c_AWPeriodPlan.cf_DeletePlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.REVIEW' = 'c_AWPeriodPlan.cf_ReviewPlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.APPROVE' = 'c_AWPeriodPlan.cf_ApprovePlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.CANCEL' = 'c_AWPeriodPlan.cf_CancelPlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.ARCHIVE' = 'c_AWPeriodPlan.cf_ArchivePlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.RESTORE' = 'c_AWPeriodPlan.cf_RestorePlan',
  'MAINTAINENCE.WINTER_PLANS.CARD.CONFIRM' = 'c_AWPeriodPlan.cf_ConfirmPlan',
  'MAINTAINENCE.WINTER_PLANS.ACTION.CARD' = 'c_AWPeriodAction',
  'MAINTAINENCE.WINTER_PLANS.ACTION.CARD.ADD' = 'c_AWPeriodAction.cf_AddAction',
  'MAINTAINENCE.WINTER_PLANS.ACTION.CARD.EDIT' = 'c_AWPeriodAction.cf_EditAction',
  'MAINTAINENCE.WINTER_PLANS.ACTION.CARD.DELETE' = 'c_AWPeriodAction.cf_DeleteAction',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD' = 'c_AWPeriodActionJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.ADD' = 'c_AWPeriodActionJob.cf_AddJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.ADD_UNPLANNED' = 'c_AWPeriodActionJob.cf_AddUnplannedJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.EDIT' = 'c_AWPeriodActionJob.cf_EditJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.DELETE' = 'c_AWPeriodActionJob.cf_DeleteJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.DELETE_UNPLANNED' = 'c_AWPeriodActionJob.cf_DeleteUnplannedJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.REPORT' = 'c_AWPeriodActionJob.cf_ReportJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.REPORT_DELETE' = 'c_AWPeriodActionJob.cf_DeleteReportJob',
  'MAINTAINENCE.WINTER_PLANS.JOB.CARD.CHANGE_CREW' = 'c_AWPeriodActionJob.cf_ChangeMaintainenceCrewJob',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST' = 'g_EmergencyWorkRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD' = 'c_EmergencyWorkRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.ADD' = 'c_EmergencyWorkRequest.cf_AddRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.EDIT' = 'c_EmergencyWorkRequest.cf_EditRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.DELETE' = 'c_EmergencyWorkRequest.cf_DeleteRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.REPORT' = 'c_EmergencyWorkRequest.cf_ReportRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.REPORT_DELETE' = 'c_EmergencyWorkRequest.cf_DeleteReportRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.CHANGE_CREW' = 'c_EmergencyWorkRequest.cf_ChangeMaintainenceCrewRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.ARCHIVE' = 'c_EmergencyWorkRequest.cf_ArchiveRequest',
  'MAINTAINENCE.EMERGENCY_WORK_REQUEST.CARD.RESTORE' = 'c_EmergencyWorkRequest.cf_RestoreRequest',
  'MAINTAINENCE.WORK_CONTRACTS' = 'g_WorkContracts',
  'MAINTAINENCE.WORK_CONTRACTS.CARD' = 'c_WorkContract',
  'MAINTAINENCE.WORK_CONTRACTS.CARD.ADD' = 'c_WorkContract.cf_AddContract',
  'MAINTAINENCE.WORK_CONTRACTS.CARD.EDIT' = 'c_WorkContract.cf_EditContract',
  'MAINTAINENCE.WORK_CONTRACTS.CARD.DELETE' = 'c_WorkContract.cf_DeleteContract',
  // Нештатные ситуации
  'MAINTAINENCE.EMERGENCY_LOG' = 'g_EmergencySituations',
  'MAINTAINENCE.EMERGENCY_LOG.CARD' = 'c_EmergencySituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.ADD' = 'c_EmergencySituation.cf_AddSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.EDIT' = 'c_EmergencySituation.cf_EditSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.ARCHIVE' = 'c_EmergencySituation.cf_ArchiveSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.DELETE' = 'c_EmergencySituation.cf_DeleteSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.REPORT' = 'c_EmergencySituation.cf_ReportSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.RESTORE' = 'c_EmergencySituation.cf_RestoreSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.ADD_POINT' = 'c_EmergencySituation.cf_AddPointSituation',
  'MAINTAINENCE.EMERGENCY_LOG.CARD.DELETE_POINT' = 'c_EmergencySituation.cf_DeletePointSituation',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS' = 'g_ResourceDisablePlans',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD' = 'c_ResourceDisablePlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.ADD' = 'c_ResourceDisablePlan.cf_AddPlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.EDIT' = 'c_ResourceDisablePlan.cf_EditPlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.DELETE' = 'c_ResourceDisablePlan.cf_DeletePlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.ARCHIVE' = 'c_ResourceDisablePlan.cf_ArchivePlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.RESTORE' = 'c_ResourceDisablePlan.cf_RestorePlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.MEASURE_POINTS.ADD' = 'c_ResourceDisablePlan.cf_AddPointPlan',
  'MAINTAINENCE.RESOURCE_DISABLE_PLANS.CARD.MEASURE_POINTS.DELETE' = 'c_ResourceDisablePlan.cf_DeletePointPlan',
  'MAINTAINENCE.VERIFICATION_PLANS' = 'g_VerificationPlans',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD' = 'c_VerificationPlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.ADD' = 'c_VerificationPlan.cf_AddPlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.EDIT' = 'c_VerificationPlan.cf_EditPlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.DELETE' = 'c_VerificationPlan.cf_DeletePlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.ARCHIVE' = 'c_VerificationPlan.cf_ArchivePlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.RESTORE' = 'c_VerificationPlan.cf_RestorePlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.DEVICES.ADD' = 'c_VerificationPlan.cf_AddEquipPlan',
  'MAINTAINENCE.VERIFICATION_PLANS.CARD.DEVICES.DELETE' = 'c_VerificationPlan.cf_DeleteEquipPlan',
  // Энергетические системы
  'ENERGY_SYSTEMS' = 'm_EnergySystems',
  'ENERGY_SYSTEMS.NETWORKS' = 'm_Networks',
  'ENERGY_SYSTEMS.NETWORKS.HEAT_ENERGY' = 'g_NetworksHE',
  'ENERGY_SYSTEMS.NETWORKS.COLD_WATER' = 'g_NetworksCW',
  'ENERGY_SYSTEMS.NETWORKS.HOT_WATER' = 'g_NetworksHW',
  'ENERGY_SYSTEMS.NETWORKS.ELECTRIC_ENERGY' = 'g_NetworksEE',
  'ENERGY_SYSTEMS.NETWORKS.GAS' = 'g_NetworksGas',
  'ENERGY_SYSTEMS.NETWORKS.DISPOSAL_WATER' = 'g_NetworksDW',
  'ENERGY_SYSTEMS.NETWORKS.CARD' = 'c_Network',
  'ENERGY_SYSTEMS.NETWORKS.CARD.SECTIONS' = 'c_Network.ct_NetworkSection',
  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD' = 'c_NetworkSection',
  'ENERGY_SYSTEMS.NETWORKS.SECTION.CARD.HISTORY' = 'c_NetworkSection.ct_History',
  // Аналитика
  'ANALYTICS' = 'm_Analytics',
  'ANALYTICS.VISUALISATION' = 'g_Visualization',
  'ANALYTICS.REPORTS' = 'g_Reports'
}

export enum GroupType {
  COMMON = 'COMMON',
  PERSONAL = 'PERSONAL',
  NO_GROUP = 'NO_GROUP',
}

export enum RoleType {
  COMMON = 'COMMON',
  PERSONAL = 'PERSONAL',
  NO_ROLE = 'NO_ROLE',
}

export enum TaskScheduleType {
  DATABASE = 'DATABASE',
  BACKEND = 'BACKEND',
}

export enum MeasureObjectNameType {
  'ОУ' = 'OU',
  'УСПД' = 'USPD',
  'УПД' = 'UPD',
  'ПУ' = 'PU',
  'ИУ' = 'IU',
  'ТУ' = 'TU',
}

export enum MeasureObjectStateIndex {
  CREATED = 1,
  NEW = 2,
  STARTUP = 3,
  WORKING = 4,
  WORKINGUSD = 5,
  WORKINGPU = 6,
  CONNECTED = 7,
  NOWORKING = 8,
  SERVICE = 9,
  ARCHIVE = 10,
  NOTINSTALLED = 11,
  MAINTENANCE = 12,
  INACTIVE = 13,
  DISMANTLED = 14,
}

export enum AddressSource {
  FIAS = 'FIAS',
  MANUAL = 'MANUAL',
  ZKH = 'ZKH',
}

export enum DeviceMeterTypeCode {
  HEAT = 'HEAT', // Тепловычислитель
  EE = 'EE', // Счетчик электроэнергии
  IMPULSE = 'IMPULSE', // Расходомер импульсный ХВС, ГВС, ВО
  GAS = 'GAS', // Расходомер Газа
  OIL = 'OIL', // Расходомер мазутного топлива
  REG = 'REG', // Регистратор импульсов
}

export enum MeasurePointType {
  INPUT = 'INPUT', // Потребление
  OUTPUT = 'OUTPUT', // Генерация
  NET = 'NET', // Сеть распределения
  WASTEWATER_INTAKE = 'WASTEWATER_INTAKE', // Прием сточных вод
  WASTEWATER_TREATMENT = 'WASTEWATER_TREATMENT', // Очистка сточных вод
}

export enum AccountType {
  COMM = 'COMM', // Коммерческий учет
  TECH = 'TECH', // Технологический учет
}

export enum CommAccountType {
  BUY = 'BUY', // Покупка
  SELL = 'SELL', // Реализация
}

export enum MeasurePointSystemType {
  CLOSED = 'CLOSED', // Закрытая"
  OPENED = 'OPENED', // Открытая
  ONE_PHASE = 'ONE_PHASE', // Однофазная
  THREE_PHASE = 'THREE_PHASE', // Трехфазная
  STUB = 'STUB', // Тупиковая
  CIRCULATING = 'CIRCULATING', // Циркуляционная
}

export enum TranformRatioTypeCode {
  V = 'V', // Напряжение
  A = 'A', // Ток
}

export enum MeasureObjectTreeContextFuncCode {
  ADD_TU_TO_OU = 'c_MeasurePoint.cf_add', // Добавить Точку учета
  CONNECT_USPD_TO_OU = 'c_DeviceTransfer.cf_ConnectToMeasureObject', // Подключить УСПД к Объекту учета
  CONNECT_UPD_TO_OU = 'c_DeviceModem.cf_ConnectToMeasureObject', // Подключить УПД к Объекту учета
  DISCONNECT_USPD_FROM_OU = 'c_DeviceTransfer.cf_DisconnectFromMeasureObject', // Отключить УСПД от Объекта учета
  DELETE_CONNECT_USPD_TO_OU = 'c_DeviceTransfer.cf_DeleteConnectToMeasureObject', // Удалить подключение УСПД к Объекту учета
  CHECK_CONNECT_WITH_USPD = 'c_DeviceTransfer.cf_CheckConnectionUSPD', // Проверка связи с УСПД
  TRANSFER_USPD_PARAMS_TO_IVK = 'c_DeviceTransfer.cf_WriteUspdParam', // Передать параметры УСПД на ИВК
  REQUEST_CONFIG_USPD = 'c_DeviceTransfer.cf_ReadUspdTree', // Запрос конфигурации УСПД
  REQUEST_INSTANT_VALUES_USPD = 'c_DeviceTransfer.cf_GetInstantMeterDataUSPD', // Запрос мгновенных значений
  REBOOT_USPD = 'c_DeviceTransfer.cf_RestartUSPD', // Перезагрузка УСПД
  TRANSFER_CONFIG_USPD = 'c_DeviceTransfer.cf_WriteUspdTree', // Передать конфигурацию на УСПД
  REREAD_ARCHIVE_DATA_USPD = 'c_DeviceTransfer.cf_RereadMeterDataUSPD', // Переопросить архивные данные УСПД
  GENERATE_PASSPORT_USPD = 'c_DeviceTransfer.cf_GeneratePassportUSPD', // выгрузка паспорта УСПД
  GENERATE_PPI_USPD = 'c_DeviceTransfer.cf_GeneratePPIUSPD', // Сформировать протокол проведения испытаний УСПД
  DISCONNECT_UPD_FROM_OU = 'c_DeviceModem.cf_DisconnectFromMeasureObject', // Отключить УПД от Объекта учета
  DELETE_CONNECT_UPD_TO_OU = 'c_DeviceModem.cf_DeleteConnectToMeasureObject', // Удалить подключение УПД к Объекту учета
  CHECK_CONNECT_WITH_UPD = 'c_DeviceModem.cf_CheckConnectionModem', // Проверка связи с УПД
  TRANSFER_UPD_PARAMS_TO_IVK = 'c_DeviceModem.cf_WriteModemParam', // Передать параметры УПД на ИВК
  REQUEST_CONFIG_UPD = 'c_DeviceModem.cf_ReadModemTree', // Запрос конфигурации УПД
  REQUEST_INSTANT_VALUES_UPD = 'c_DeviceModem.cf_GetInstantMeterDataModem', // Запрос мгновенных значений
  REBOOT_UPD = 'c_DeviceModem.cf_RestartModem', // Перезагрузка УПД
  TRANSFER_CONFIG_UPD = 'c_DeviceModem.cf_WriteModemTree', // Передать конфигурацию на УПД
  REREAD_ARCHIVE_DATA_UPD = 'c_DeviceModem.cf_RereadMeterDataModem', // Переопросить архивные данные УПД
  GENERATE_PASSPORT_UPD = 'c_DeviceModem.cf_GeneratePassportModem', // выгрузка паспорта модема (УПД)
  GENERATE_PPI_UPD = 'c_DeviceModem.cf_GeneratePPIModem', // Сформировать протокол проведения испытаний УПД
  CONNECT_PU_TO_USD = 'c_DeviceMeter.cf_ConnectToUSD', // Подключить ПУ к УСД
  DISCONNECT_PU_FROM_USD = 'c_DeviceMeter.cf_DisconnectFromUSD', // Отключить ПУ от УСД
  DELETE_CONNECT_PU_TO_USD = 'c_DeviceMeter.cf_DeleteConnectToUSD', // Удалить подключение ПУ к УСД
  DISCONNECT_IU_FROM_TU = 'c_DeviceMeasure.cf_DisconnectFromMeasurePoint', // Отключить ИУ от ТУ
  DELETE_CONNECT_IU_TO_TU = 'c_DeviceMeasure.cf_DeleteConnectToMeasurePoint', // Удалить подключение ИУ к ТУ
  CONNECT_IU_TO_TU = 'c_DeviceMeasure.cf_ConnectToMeasurePoint', // Подключить ИУ к ТУ
  CONNECT_TU_TO_PU = 'c_MeasurePoint.cf_ConnectToDeviceMeter', // Подключить ТУ к ПУ
  DISCONNECT_TU_FROM_PU = 'c_MeasurePoint.cf_DisconnectFromDeviceMeter', // Отключить ТУ от ПУ
  DELETE_CONNECT_TU_TO_PU = 'c_MeasurePoint.cf_DeleteConnectToDeviceMeter', // Удалить подключение ТУ к ПУ
  OPEN_INSTANT_JOURNAL = 'c_MeasurePoint.cf_OpenInstantJournal', // Открыть журнал мгновенных параметров
  OPEN_ARCHIVE_JOURNAL = 'c_MeasurePoint.cf_OpenArchiveJournal', // Открыть журнал архивных параметров
  OPEN_INPUT_JOURNAL = 'c_MeasurePoint.cf_OpenManualInputJournal', // Открыть журнал вводимых в ручную параметров
  REREAD_ARCHIVE_DATA_TU = 'c_MeasurePoint.cf_RereadMeterDataTU', // Переопросить архивные данные ТУ
  DOWNLOAD_REPORT_TG_TU = 'g_MeasurePoints.gf_DownloadReportTG', // Выгрузить отчет по нарушениям ТГ
  CREATE_DAILY_MEASURE_REPORT = 'c_MeasureReport.cf_ReportDailyCreate', // Сфоромировать посуточную ведомость
  CREATE_DAILY_WITH_CALC_MEASURE_REPORT = 'c_MeasureReport.cf_ReportDailyRecalcCreate', // Сфоромировать посуточную ведомость с досчетом или расчетом
  CREATE_HOURLY_MEASURE_REPORT = 'c_MeasureReport.cf_ReportHourlyCreate', // Сформировать почасовую ведомость
  CREATE_POWER_PROFILE_MEASURE_REPORT = 'c_MeasureReport.cf_ReportPwrProfileCreate', // Сформировать профиль мощности
  // connections cards
  OPEN_USPD_TO_OU_CONNECT_CARD = 'c_DeviceTransfer.cf_EditConnectionToMeasureObject', // Открыть карточку подключения к ОУ
  OPEN_UPD_TO_OU_CONNECT_CARD = 'c_DeviceModem.cf_EditConnectionToMeasureObject', // Открыть карточку подключения к ОУ
  OPEN_PU_TO_USD_CONNECT_CARD = 'c_DeviceMeter.cf_EditConnectionToUSD', // Открыть карточку подключения к УСД
  OPEN_IU_TO_TU_CONNECT_CARD = 'c_DeviceMeasure.cf_EditConnectionToMeasurePoint', // Открыть карточку подключения к ПУ
  OPEN_TU_TO_PU_CONNECT_CARD = 'c_MeasurePoint.cf_EditConnectionToDeviceMeter', // Открыть карточку подключения к ТУ
  REREAD_PU_METER_DATA = 'c_DeviceMeter.cf_RereadMeterDataPU', // Переопросить архивные параметры ПУ
  USPD_ADD_PSI = 'c_DeviceTransfer.cf_AddPSI', // Добавить запись в журнал ПСИ для УСПД
  UPD_ADD_PSI = 'c_DeviceModem.cf_AddPSI', // Добавить запись в журнал ПСИ для УПД
}

export enum MeasureParamType {
  INSTANT = 'INSTANT', // Мгновенные
  ARCHIVE = 'ARCHIVE', // Архив
  HOURLY = 'HOURLY', // Часовой архив
  DAILY = 'DAILY', // Суточный архив
  MONTHLY = 'MONTHLY', // Месячный архив
  YEARLY = 'YEARLY', // Годовой архив
}

/**
 * Типы пароля электросчетчика
 */
export enum EPasswordTypeCodesEE {
  HEX = 'HEX',
  ASCI = 'ASCII',
}

export enum Microservice {
  MAIN = 'WS_WEB_BACKEND',
}

export enum ENsiCodes {
  PORT_TYPE = 'PORT_TYPE', //Тип порта
  MEASURE_PARAM = 'MEASURE_PARAM', //Параметры учета ресурсов
  MEASURE_TOOL_NAME = 'MEASURE_TOOL_NAME', //Наименование средства измерения
  DEVICE_MODEL = 'DEVICE_MODEL', //Модель оборудования
  ADDRESS_STATE = 'ADDRESS_STATE', //Адреса. Статус актуальности адресообразующего элемента
  ACCOUNTING_TYPE = 'ACCOUNTING_TYPE', //Тип учета', 'LINK_TYPE = "LINK_TYPE", //Тип подключения
  PIPELINE_TYPE = 'PIPELINE_TYPE', //Тип трубопровода
  ORG = 'ORG', //Организации,
  GSM_SIGNAL_LEVEL = 'GSM_SIGNAL_LEVEL', //Уровень сигнала GSM
  TRANSFORMATION_RATIO_TYPE = 'TRANSFORMATION_RATIO_TYPE', //Тип коэффициента трансформации
  MEASURE_TOOL_TYPE = 'MEASURE_TOOL_TYPE', //Тип средства измерения
  MEASURE_POINT_TYPE = 'MEASURE_POINT_TYPE', //Тип точки учета
  HEAT_TRANSFER_TYPE = 'HEAT_TRANSFER_TYPE', //Тип теплоносителя
  TCP_PORT_MODE = 'TCP_PORT_MODE', //Режим работы TCP-порта',
  ADDRESS_ESTATE_TYPE = 'ADDRESS_ESTATE_TYPE', //Адреса. Вид владения (недвижимости)
  ORG_ROLE = 'ORG_ROLE', //Роль организации
  USD_DEV_TYPE = 'USD_DEV_TYPE', //Вид оборудования для УСД
  RES_TYPE = 'RES_TYPE', //Тип ресурса
  TRANSFORMATION_RATIO = 'TRANSFORMATION_RATIO', //Коэффициент трансформации
  SYSTEM_TYPE = 'SYSTEM_TYPE', //Тип системы
  TIME_ZONE = 'TIME_ZONE', //Часовые зоны
  WATER_SYSTEM_TYPE = 'WATER_SYSTEM_TYPE', //Тип холодного водоснабжения
  WATER_TREATMENT_TYPE = 'WATER_TREATMENT_TYPE', //Способ водоподготовки
  MEASURE_OBJECT_CATEGORY = 'MEASURE_OBJECT_CATEGORY', //Категория объекта учета
  ADDRESS = 'ADDRESS', //Адреса. Адресный объект
  ADDRESS_OBJECT_LEVEL = 'ADDRESS_OBJECT_LEVEL', //Адреса. Уровни адресных объектов
  PASSWORD_TYPE_EE = 'PASSWORD_TYPE_EE', //Тип пароля электросчетчика
  ADDRESS_TREE = 'ADDRESS_TREE', //Адреса. Адресообразующий элемент
  DEVICE_METER_PARITY = 'DEVICE_METER_PARITY', //Четность ПУ
  MEASURE_PARAM_TYPE = 'MEASURE_PARAM_TYPE', //Тип данных ПУ
  VENDOR = 'VENDOR', //Производитель
  DEV_TYPE = 'DEV_TYPE', //Вид оборудования
  ADDRESS_BUILDING_STATE = 'ADDRESS_BUILDING_STATE', //Адреса. Состояние дома
  ADDRESS_BUILDING_TYPE = 'ADDRESS_BUILDING_TYPE', //Адреса. Вид строения
  NETWORK_TYPE = 'NETWORK_TYPE', //Тип сети
  ADDRESS_OBJECT_TYPE = 'ADDRESS_OBJECT_TYPE', //Адреса. Тип и сокращение адресообразующего элемента
  ACTIVE_CONNECTION_TYPE = 'ACTIVE_CONNECTION_TYPE', //Тип активного подключения
  TCP_PORT_TYPE = 'TCP_PORT_TYPE', //Тип TCP-порта
  MODEM_CONNECTION_TYPE = 'MODEM_CONNECTION_TYPE', //Тип подключения модема
  MEASURE_UNIT = 'MEASURE_UNIT', //Единицы измерения физических величин
  DEVICE_METER_TYPE = 'DEVICE_METER_TYPE', //Тип прибора учета
  MILITARY_TOWN = 'MILITARY_TOWN', //Военный городок
  IMPORT_TYPE = 'IMPORT_TYPE', //Тип загрузки
  PHOTO_TYPE = 'PHOTO_TYPE', //Тип фотографии
  USD_PORT_NUMBER = 'USD_PORT_NUMBER', //Номер порта УСД
  MEASURE_OBJECT_TYPE = 'MEASURE_OBJECT_TYPE', //Тип объекта учета
  HEAT_METER_WORK_NODE = 'HEAT_METER_WORK_NODE', //Режим работы теплосчетчика
  ACCURACY_CLASS_EE = 'ACCURACY_CLASS_EE', //Класс точности счетчиков электроэнергии
  ALERT_TYPE = 'ALERT_TYPE', //Тип события ИВК
  DOCUMENT_TYPE = 'DOCUMENT_TYPE', //Тип документа
}

export enum DeliveryContractStateType {
  DELIVERY_CONTRACT = 'DELIVERY_CONTRACT',
  DELIVERY_CONTRACT_ADDITIONAL = 'DELIVERY_CONTRACT_DETAIL',
}

export enum DeliveryContractStatesIcons {
  DC_DRAFT = 'icon-registered',
  DC = 'icon-supply_contract',
  DC_DELETED = 'icon-deleted',
}

export enum DeliveryContractState {
  DELETED = 'DC_DELETED',
  DRAFT = 'DC_DRAFT',
  DC = 'DC',
}

export enum DeliveryContractAdditionalStatesIcons {
  DCD_DRAFT = 'icon-registered',
  DCD = 'icon-supply_contract',
  DCD_DELETED = 'icon-deleted',
}

export enum DeliveryContractAdditionalState {
  DELETED = 'DCD_DELETED',
  DRAFT = 'DCD_DRAFT',
  DCD = 'DCD',
}

export enum RoomTemperNormType {
  BOUND_LOW = 'BOUND_LOW',
  BOUND_UP = 'BOUND_UP',
}

export enum TemplateReportType {
  HOURLY = 'HOURLY', // Почасовой
  DAILY = 'DAILY', // Посуточный
  POWER_PROFILE = 'POWER_PROFILE', // Профиль мощности
}

export enum AnalyticsReportType {
  All = 'ALL',
  VIOLATIONS = 'VIOLATIONS'
}

export enum AnalyticsReportGraphType {
  TEMPERATURE_GRAPH = 'TEMPERATURE_GRAPH',
  REGIME_MAP = 'REGIME_MAP',
}

export enum AnalyticsReportAnalysisReportType {
  // Значения enum связаны с переводами
  Balance = 'BALANCE',
  UnitCosts = 'UNIT_COSTS'
}

export enum TemplateReportGroup {
  USER = 'USER', // Пользовательский
  SYSTEM = 'SYSTEM', // Общесистемный
}

export enum BoilerTypeCode {
  WATER = 'WATER', // Водогрейный
  VAPOR = 'VAPOR', // Паровой
}

export enum FuelTypeCode {
  GAS = 'GAS', // Газообразное
  LIQUID = 'LIQUID', // Жидкое
  SOLID = 'SOLID', // Твердое
}

export enum HeatTransferTypeCode {
  LIQUID = 'LIQUID', // Вода
  VAPOR = 'VAPOR' // Пар
}

export enum FuelKindCode {
  MASUT = 'MASUT', // Мазут
  DIESEL = 'DIESEL', // Дизель
  SPG = 'SPG', // СПГ
  SUG = 'SUG', // СУГ
  COAL = 'COAL', // Уголь
  PEAT = 'PEAT', // Торф
  PELLETS = 'PELLETS', // Пеллеты
  PSG = 'PSG', // Природный сетевой газ
}

export enum NurCalcMethodCode {
  KPD_GROSS = 'KPD_GROSS', // Расчет через КПД брутто котлоагрегата во всем диапазоне нагрузки
  OVERALL_COEFFICIENT = 'OVERALL_COEFFICIENT' // Расчет через Общий (интегральный) коэффициент К1
}

export enum CardRegimes {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}

export enum ElectromotorPurposeCode {
  FAN = 'FAN', // Вентиляторный
  PUMP = 'PUMP', // Насосный
}

export enum ManufactureMaterialCode {
  STEEL = 'STEEL', // Сталь
  BRICK = 'BRICK', // Кирпич
  CONCRETE = 'CONCRETE', // Бетон
  POLYMER = 'POLYMER', // Полимерные
  METAL = 'METAL', // Метал
  POLYETHYLENE = 'POLYETHYLENE', // Полиэтилен
}

export enum WellheadValveImplTypeCode {
  GATE_VALVE = 'GATE_VALVE', // Задвижка
  BALL_VALVE = 'BALL_VALVE', // Шаровой кран
  DISK_VALVE = 'DISK_VALVE', // Дисковый затвор
}

export enum EngineerDeviceType {
  BOILER = 'BOILER', // Котел
  PUMP = 'PUMP_EQ', // Насосное оборудование
  HEAT = 'HEAT_EQ', // Теплообменное оборудование
  CHEMICAL_WATER_TREATMENT = 'CHEMICAL_WATER_TREATMENT_EQ', // Оборудование химводоподготовки
  EE_GEN_MAIN = 'EE_GEN_MAIN', // Основной источник электрической энергии
  EE_GEN_RESERVE = 'EE_GEN_RESERVE', // Резервный источник электрической энергии
  SMOKE_FAN = 'SMOKE_FAN', // Вентилятор системы дымоудаления
  COMPRESSOR = 'COMPRESSOR', // Компрессор
  CHIMNEY = 'CHIMNEY', // Дымовая труба
  GAS_ANALYZER = 'GAS_ANALYZER', // Газоанализатор
  DEAERATOR = 'DEAERATOR', // Деаэратор
  DECARBONIZER = 'DECARBONIZER', // Декарбонизатор
  STEAM_LINE = 'STEAM_LINE', // Паропровод
  TRANSFORMER_SUBSTATION = 'TRANSFORMER_SUBSTATION', // Трансформаторная подстанция
  OUT_LIGHT_CONTROL_LOCKER = 'OUTDOOR_LIGHTING_CONTROL_LOCKER', // Шкафы управления наружным освещением
  GAS_DISTRIBUTION = 'GAS_DISTRIBUTION_DEVICES', // Газораспределительные устройства
  CONTROL_VALVES = 'CONTROL_VALVES', // ЗРА (запорно-регулирующая арматура)
  GAS_PIPELINE = 'GAS_PIPELINE', // Газопровод
  HEATING_NETWORK = 'HEATING_NETWORK', // Тепловая сеть
  WATER_TOWER = 'WATER_TOWER', // Водонапорная башня
  ECONOMIZER = 'ECONOMIZER', // Экономайзер
  WATER_STORAGE = 'WATER_STORAGE', // Емкость запаса воды
  EVAPORATIVE_PLANT = 'EVAPORATIVE_PLANT', // Испарительная установка
  SWITCH = 'SWITCH', // Выключатель
  UNIT = 'UNIT', // Агрегат
  SWITCHGEAR = 'SWITCHGEAR', // Распределительное устройство
  CELL_RU = 'CELL_RU', // Ячейка РУ
  TRANSFORMER_AMPERAGE = 'TRANSFORMER_AMPERAGE', // Трансформатор тока
  TRANSFORMER_VOLTAGE = 'TRANSFORMER_VOLTAGE', // Трансформатор напряжения
  DEVICE_RZA = 'DEVICE_RZA', // Устройство РЗА
  PANEL = 'PANEL', // Панель 0,4 кВ
  ELECTRIC_MOTOR = 'ELECTRIC_MOTOR', // Электродвигатель
  PRESSURE_REDUCER = 'PRESSURE_REDUCER', // Редуктор давления
  FILTER = 'FILTER', // Фильтр
  VALVE = 'VALVE', // Клапан
  STORAGE_TANK = 'STORAGE_TANK', // Бак-аккумулятор
  MANOMETER = 'MANOMETER', // Манометр
  THERMOMETER = 'THERMOMETER', // Термометр
  SLEEVE = 'SLEEVE', // Гильза
}

export enum EngineerDeviceState {
  STARTUP = 'STARTUP', // Пусконаладка
  WORKING = 'WORKING', // Эксплуатируется
  INACTIVE = 'INACTIVE', // Законсервирован
  MAINTENANCE = 'MAINTENANCE', // ТО и ремонт
  DISMANTLED = 'DISMANTLED', // Демонтирован
}

export enum EngineerDeviceStateIcon {
  STARTUP = 'icon-registered', // Пусконаладка
  WORKING = 'icon-good', // Эксплуатируется
  INACTIVE = 'icon-mothballed_state', // Законсервирован
  MAINTENANCE = 'icon-service', // ТО и ремонт
  DISMANTLED = 'icon-dismantled_state', // Демонтирован
}

export enum RegimeMapEditVersionDateModes {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export enum RegimeMapVersionStates {
  DRAFT = 'DRAFT', // Черновик версии ШРК
  FUTURE = 'FUTURE', // Следубщая версия ШРК
  CURRENT = 'CURRENT', // Текущая версия ШРК
  ARCHIVE = 'ARCHIVE', // Архивная версия ШРК
}

export enum RegimeMapVersionStateIcons {
  DRAFT = 'icon-registered', // Черновик версии ШРК
  FUTURE = 'icon-future_version', // Следубщая версия ШРК
  CURRENT = 'icon-current_version', // Текущая версия ШРК
  ARCHIVE = 'icon-arhive_version', // Архивная версия ШРК
}

// статусы Режимной карты ТУ
export enum MeasurePointRegimeMapStates {
  ARCHIVE = 'ARCHIVE', // архивная
  ACTUAL = 'ACTUAL', // текущая
  FUTURE = 'FUTURE', // будущая
}

// иконки статусов Отопительного сезона
export enum MeasurePointRegimeMapStateIcons {
  FUTURE = 'icon-future_version',
  ACTUAL = 'icon-current_version',
  ARCHIVE = 'icon-arhive_version',
}

// статусы Отопительного сезона
export enum RegimeSeasonStates {
  ARCHIVE = 'ARCHIVE', // архивный
  ACTUAL = 'ACTUAL', // текущий
  FUTURE = 'FUTURE', // будущий
}

// иконки статусов Отопительного сезона
export enum RegimeSeasonStateIcons {
  FUTURE = 'icon-future_version',
  ACTUAL = 'icon-current_version',
  ARCHIVE = 'icon-arhive_version',
}

export enum ModemConnectionType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
}

export enum BroadcastChannelKeys {
  AUTH = 'auth-broadcast-channel',
}

// статусы Журнала режимов работы
export enum MeasureObjectWorkJournalStates {
  ARCHIVED = 'ARCHIVED', // архивный
  ACTUAL = 'ACTUAL', // текущий
}

// иконки статусов Журнала режимов работы
export enum MeasureObjectWorkJournalStateIcons {
  ACTUAL = 'icon-current_version',
  ARCHIVED = 'icon-arhive_version',
}

export enum PsiResultCode {
  OK = 'OK', // Принято
  PART = 'PART', // Частично принято
  NOT_OK = 'NOT_OK', // Не принято
}

/**
 * Место вызова справочника Тип загрузки
 */
export enum EUploadTypeCallPlaceCode {
  DEVICE_LIST = 'DEVICE_LIST',
  OBJECT_CARD = 'OBJECT_CARD',
}

export enum DocumentCategory {
  VERIFICATION = 'VERIFICATION', // Поверка
  EXPLOITATION = 'EXPLOITATION', // Эксплуатация
  STAGING = 'STAGING', // Постановка на КУ
  OTHER = 'OTHER', // Прочие
}

export enum DocumentsType {
  PASSPORT_USPD = 'PASSPORT_USPD', // Паспорт УСПД
  PASSPORT_UPD = 'PASSPORT_UPD', // Паспорт УПД
  PASSPORT_PU = 'PASSPORT_PU', // Паспорт ПУ
  PASSPORT_IU = 'PASSPORT_IU', // Паспорт ИУ
  VERIFICATION_CERTIFICATE = 'VERIFICATION_CERTIFICATE', // Свидетельство о поверке
  NOTIFICATION_OF_UNSUITABILITY = 'NOTIFICATION_OF_UNSUITABILITY', // Извещение о непригодности средства измерения
  ACT_OF_COMMISSIONING = 'ACT_OF_COMMISSIONING', // Акт ввода в эксплуатацию
  ACT_OF_DECOMMISSIONING = 'ACT_OF_DECOMMISSIONING', // Акт вывода из эксплуатации
  ACT_OF_COMMERCIAL_REGISTRATION = 'ACT_OF_COMMERCIAL_REGISTRATION', // Акт постановки ПУ на Коммерческий учет
  ACT_OF_DECOMMERCIAL_REGISTRATION = 'ACT_OF_DECOMMERCIAL_REGISTRATION', // Акт снятия ПУ с Коммерческого учета
}

// Режимы карточки Перемещения топлива
export enum FuelRelocationCardRegimes {
  VIEW = 'view', // Просмотр перемещения топлива
  ADD = 'add', // Добавить перемещение топлива
  EDIT = 'edit', // Изменить перемещение топлива
  ACCEPT = 'accept', // Принять перемещение топлива
  REFUND = 'refund', // Вернуть перемещение топлива
  ACCEPT_REFUND = 'accept_refund', // Принять возврат топлива
}

// Cтатусы перемещения
export enum FuelRelocationStates {
  NEW = 'NEW', // Новый
  IN_TRANSIT = 'IN_TRANSIT', // В пути
  COMPLETE_ALL = 'COMPLETE_ALL', // Доставлено полностью
  COMPLETE_PART = 'COMPLETE_PART', // Доставлено не полностью
  REFUND = 'REFUND', // Возврат
  REFUND_ALL = 'REFUND_ALL', // Полный возврат
  REFUND_PART = 'REFUND_PART', // Возвращено не полностью
}

// Иконки статусов перемещения
export enum FuelRelocationStateIcons {
  NEW = 'icon-new', // Новый
  IN_TRANSIT = 'icon-transfer', // В пути
  COMPLETE_ALL = 'icon-good', // Доставлено полностью
  COMPLETE_PART = 'icon-complete-part', // Доставлено не полностью
  REFUND = 'icon-on_completion', // Возврат
  REFUND_ALL = 'icon-refund-all', // Полный возврат
  REFUND_PART = 'icon-refund-part', // Возвращено не полностью
}

export enum FuelChangeTypes {
  FUEL_REMAINING_RATE = 'FUEL_REMAINING_RATE', // Создание нормы остатков топлива
  SUPPLY = 'SUPPLY', // поставка топлива
  DAILY_CONSUMPTION = 'DAILY_CONSUMPTION', // суточный расход топлива
  INVENTORY = 'INVENTORY', // инвентаризация топлива
  OUTBOUND_MOVEMENT = 'OUTBOUND_MOVEMENT', // Исходящее перемещение
  INBOUND_MOVEMENT = 'INBOUND_MOVEMENT', // Входящее перемещение
}

// Тип инженерного узла
export enum EngineerNodeTypes {
  HEAT_CAMERA = '10', // тепловая камера
  WELL = '20', // колодец
  ELECTRICITY = '30', // инженерный узел электросети
  DISTRIBUTION_CENTER = '40', // распределительный узел
}

// Статусы согласования
export enum ApproveStates {
  EMPTY = 'EMPTY', // Отсутствует
  APPROVED = 'APPROVED', // Согласовано
  APPROVED_RC = 'APPROVED_RC', // Согласовано с досчетом
  REJECTED = 'REJECTED' // Отклонено
}

// иконки Статусов согласования
export enum ApproveStateIcons {
  EMPTY = 'icon-calendar_time', // Отсутствует
  APPROVED = 'icon-boolean-true', // Согласовано или Согласовано с досчетом
  REJECTED = 'icon-boolean-false' // Отклонено
}

//#region ТОИР
/**
 * Коды типов планов ТОИР
 */
export enum EToirPlanTypeCode {
  /**
   * План подготовки к осенне-зимнему периоду
   */
  AWP_PREPARATION_PLAN = 'AWP_PREPARATION_PLAN',
  /**
   * План проведения обследования
   */
  CHECKUP_PLAN = 'CHECKUP_PLAN',
  /**
   * План выполнения ремонтных работ
   */
  MAINTAINENCE_PLAN = 'MAINTAINENCE_PLAN',
}
/**
 * Коды статусов выполнения работ ТОИР
 * (из справочника статусов работ)
 */
export enum EToirPlanActionJobState {
  /**
   * Выполнено полносью
   */
  COMPLETED = 'COMPLETED',
  /**
   * Выполнено частично
   */
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  /**
   * Не выполнено
   */
  NOT_COMPLETED = 'NOT_COMPLETED',
}
/**
 * Иконки кодов статусов выполнения работ ТОИР
 */
export enum EToirPlanActionJobStateIcon {
  /**
   * Выполнено полносью
   */
  COMPLETED = 'icon-good',
  /**
   * Выполнено частично
   */
  PARTIALLY_COMPLETED = 'icon-success_with_error',
  /**
   * Не выполнено
   */
  NOT_COMPLETED = 'icon-not_work',
}
/**
 * Коды причины выполнения работ ТОИР
 */
export enum EToirPlanActionJobReasonCode {
  /**
   * Выявленные недостатки при выполнении мероприятий обследования оборудования ОУ
   */
  CHECKUP_FAULT = 'CHECKUP_FAULT',
  /**
   * Заявка на проведение аварийных работ
   */
  EMERGENCY_REQUEST = 'EMERGENCY_REQUEST',
  /**
   * Обращение потребителя
   */
  CUSTOMER_REQUEST = 'CUSTOMER_REQUEST',
  /**
   * Календарный план работ
   */
  CALENDAR_PLAN = 'CALENDAR_PLAN',
  /**
   * Иное
   */
  OTHER = 'OTHER'
}
/**
 * Коды состояния оборудования
 */
export enum EToirDeviceConditionState {
  /**
   * Аварийное состояние
   */
  FAULT = 'FAULT',
  /**
   * Ремонт не требуется
   */
  NO_MAINTAINENCE_REQUIRED = 'NO_MAINTAINENCE_REQUIRED',
  /**
   * Работоспособность восстановлена
   */
  RESTORED = 'RESTORED',
}

/**
 * Коды статусов завершения работ ТОИР
 */
export enum EToirPlanWorkCompletionStatus {
  /**
   * Незавершены, не просрочены
   */
  EAM_WORK_COMPLETION_UNFINISHED_OK = 'EAM_WORK_COMPLETION_UNFINISHED_OK',
  /**
   * Незавершены, просрочены
   */
  EAM_WORK_COMPLETION_UNFINISHED_WARN = 'EAM_WORK_COMPLETION_UNFINISHED_WARN',
  /**
   * Завершены в срок
   */
  EAM_WORK_COMPLETION_FINISHED_OK = 'EAM_WORK_COMPLETION_FINISHED_OK',
  /**
   * Завершены с задержкой
   */
  EAM_WORK_COMPLETION_FINISHED_WARN = 'EAM_WORK_COMPLETION_FINISHED_WARN',
}

/**
 * Тип шаблона файла
 */
export enum CheckupPlanTemplateType {
  /**
   * Акт гидроиспытания
   */
  HYDROTEST = 'hydrotest',
  /**
   * Акт осмотра
   */
  INSPECTION = 'inspection',
  /**
   * Акт периодической проверки ПУ
   */
  METER_INSPECTION = 'meter-inspection'
}

export enum WorkContractState {
  FUTURE = 'FUTURE',
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE'
}

export enum WorkContractStateIcon {
  FUTURE = 'icon-future_version',
  ACTIVE = 'icon-current_version',
  ARCHIVE = 'icon-in_archive'
}

export enum WorkContractEndDateIcon {
  EXPIRED = 'icon-contract_date_expired',
  DAYS_90 = 'icon-contract_date_90',
  DAYS_180 = 'icon-contract_date_180'
}

export const ExpiryDateIcons = {
  minPlanEndDate: <IExpiryDateIcons>{
    sixMonth: 'icon-min_end_plan_date_6',
    threeMonth: 'icon-min_end_plan_date_3',
    expired: 'icon-min_end_plan_date',
    sixMonthHover: 'icon-min_end_plan_date_6_hover',
    threeMonthHover: 'icon-min_end_plan_date_3_hover',
    expiredHover: 'icon-min_end_plan_date_hover'
  },
  warrantyEndDate: <IExpiryDateIcons>{
    sixMonth: 'icon-warranty_expiry_date_6',
    threeMonth: 'icon-warranty_expiry_date_3',
    expired: 'icon-warranty_expiry_date',
    sixMonthHover: 'icon-warranty_expiry_date_6_hover',
    threeMonthHover: 'icon-warranty_expiry_date_3_hover',
    expiredHover: 'icon-warranty_expiry_date'
  },
  checkEndDate: <IExpiryDateIcons>{
    sixMonth: 'icon-verify_expiry_date_6',
    threeMonth: 'icon-verify_expiry_date_3',
    expired: 'icon-end_expiry_date',
    sixMonthHover: 'icon-verify_expiry_date_6_hover',
    threeMonthHover: 'icon-verify_expiry_date_3_hover',
    expiredHover: 'icon-end_expiry_date'
  }
}

export enum MeasureObjectTypeCode {
  BOILER = 'КОТЕЛЬНАЯ',
  BOILER_WITH_NETWORK = 'КОТЕЛЬНАЯ С СЕТЬЮ'
}

/**
 * Статусы плана отключения ресурсов
 */
export enum ResourceDisableState {
  EAM_PS_DRAFT = 'EAM_PS_DRAFT',
  EAM_PS_FUTURE = 'EAM_PS_FUTURE',
  EAM_PS_ACTIVE = 'EAM_PS_ACTIVE',
  EAM_PS_COMPLETED = 'EAM_PS_COMPLETED',
  EAM_PS_ARCHIVE = 'EAM_PS_ARCHIVE'
}

export enum ResourceDisableStateIcon {
  EAM_PS_DRAFT = 'icon-registered',
  EAM_PS_FUTURE = 'icon-future_version',
  EAM_PS_ACTIVE = 'icon-service',
  EAM_PS_COMPLETED = 'icon-current_version',
  EAM_PS_ARCHIVE = 'icon-in_archive',
}

export enum ResourceTypeCategory {
  HEAT_SUPPLY = 'heat-supply',
  WATER_SUPPLY = 'water-supply',
  ELECTRICT_ENERGY_SUPPLY = 'electric-energy-supply'
}

/**
 * Статусы плана поверок
 */
export enum VerificationPlanState {
  FUTURE = 'FUTURE',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  ARCHIVE = 'ARCHIVE'
}

export enum VerificationPlanStateIcon {
  FUTURE = 'icon-future_version',
  ACTIVE = 'icon-service',
  COMPLETED = 'icon-current_version',
  ARCHIVE = 'icon-in_archive',
}

//#endregion


export enum OwnNeedsCode {
  PURGE_BOILER_ROOM = 'PURGE_BOILER_ROOM', // "Потери тепловой энергии с продувочной водой"
  KIDLING_BOILER_ROOM = 'KIDLING_BOILER_ROOM', //"Расход тепловой энергии за расчетный период на растопку котлов"
  STEAM_BOILER_BLOWING = 'STEAM_BOILER_BLOWING', // "Расход тепловой энергии на обдувку поверхностей нагрева паровых котлов"
  OWN_NEEDS_OIL = 'OWN_NEEDS_OIL', // "Расход тепла на нужды мазутного хозяйства"
  OIL_DRAIN_HEATING_LOSSES = 'OIL_DRAIN_HEATING_LOSSES', // "Количество тепловой энергии на разогрев мазута при сливе"
  OIL_STORAGE_LOSSES = 'OIL_STORAGE_LOSSES', // "Потери тепловой энергии при хранении мазута"
  OIL_HEATING_PIPELINE = 'OIL_HEATING_PIPELINE', // "Расход тепловой энергии на обогрев мазутопроводов"
  OIL_HEATING_LOSSES = 'OIL_HEATING_LOSSES', // "Расход тепловой энергии на подогрев мазута"
  STEAM_OIL_SPRAY_LOSSES = 'STEAM_OIL_SPRAY_LOSSES', // "Расход тепловой энергии на паровой распыл мазута"
  TECHNOLOGICAL_NEEDS_LOSSES = 'TECHNOLOGICAL_NEEDS_LOSSES', // "Расход тепловой энергии на технологические нужды химводоочистки"
  HEATING_LOSSES = 'HEATING_LOSSES', // "Часовой расход тепловой энергии, Гкал, на отопление помещения котельной"
  BOILERS_LOSSES = 'BOILERS_LOSSES', // "Потери тепловой энергии котлоагрегатами"
  TANK_LOSSES_HEAT = 'TANK_LOSSES_HEAT', // "Потери тепловой энергии баками различного назначения (декарбонизаторы, баки-аккумуляторы и пр.)"
  HOUSEHOLD_GOODS_LOSSES = 'HOUSEHOLD_GOODS_LOSSES', // "Расход тепловой энергии на хозяйственно-бытовые нужды, Гкал"
  OWN_NEEDS_OTHER = 'OWN_NEEDS_OTHER', // "Другие потери"
  OWN_NEEDS_BLOW = 'OWN_NEEDS_BLOW', // "Расход тепловой энергии на дутье под решетки слоевых топок котлов, работающих на углях, принимается по опытным данным"
  HEAT_GENERATION = 'HEAT_GENERATION', // "Выработка тепла, Гкал"
  TOTAL = 'TOTAL', // "Итого"
}

export enum FuelWriteOffType {
  FUEL_WRITE_OFF_DAILY = 'FUEL_WRITE_OFF_DAILY',
  FUEL_WRITE_OFF_FINAL = 'FUEL_WRITE_OFF_FINAL'
}
