import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, share } from 'rxjs/operators';
import {
  IBoilerBrandDictionaryParams,
  IChemicalTreatmentSystemBrandDictionaryParams,
  IChimneyBrandDictionaryParams,
  ICompressorBrandDictionaryParams,
  IDeaeratorBrandDictionaryParams,
  IDecarbonizerBrandDictionaryParams,
  IDeliveryContractParamDictionaryParams,
  IDeviceDictionaryParams,
  IDeviceModelDictionaryParams,
  IDieselGeneratorBrandDictionaryParams,
  IEconomizerBrandDictionaryParams,
  IElectromotorBrandDictionaryParams,
  IEngineeringDeviceIdDictionaryParams,
  IEvaporPlantBrandDictionaryParams,
  IFanBrandDictionaryParams,
  IGazAnalyzerBrandDictionaryParams,
  IHeatExchangerBrandDictionaryParams,
  IMeasureReportTemplateDictionaryParams,
  IMeasureToolDictionaryParams,
  IMeasureToolTypeDictionaryParams, IMilitaryDistrictDictionaryParams,
  IOrganizationDepartmentDictionaryParams,
  IOrganizationDictionaryParams,
  IOrganizationRoleDictionaryParams,
  IOrganizationTreeDictionaryParams,
  IOrganizationTreeTypeDictionaryParams,
  IPumpBrandDictionaryParams, IRegionPlaceDictionaryParams,
  ISwitchBrandDictionaryParams,
  ISystemTypesParams,
  ITimeZoneGroup,
  ITransformRatioDictionaryParams, ITroopTypeDictionaryParams,
  IUiObjectDictionaryParams,
  IUserDictionaryParams,
  IUserRoleDictionaryParams,
  IValveBrandDictionaryParams,
  IVendorDictionaryParams,
  IVerificationMethodDictionaryParams,
  IWellheadFittingBrandDictionaryParams,
  IWorkTypeDictionaryParams,
} from '@core/interfaces/dictionary';
import { Observable, of } from 'rxjs';
import { IAddressTreeDictionaryParams } from '@core/interfaces/address';
import { RoomTemperNormType, FuelTypeCode, EUploadTypeCallPlaceCode, DocumentCategory } from '@app/app.enums';
import {
  ISelectItem,
  IDictionaryBody,
  IDictionaryParams,
  IDictionaryParamsWithActual,
} from 'web-frontend-component-library/interfaces';
import { ISMStatusesDictionaryParams } from '@app/core/interfaces/status-model';
import { UtilsService } from 'web-frontend-component-library/services/utils';
import { MeasureObjectType, ResourceTypeCode } from 'web-frontend-component-library/enums';
import { ITGDictionaryParams } from '@app/core/interfaces/temperature-graphs';
import {
  IFormulasRegistryElementsDictionaryParams,
  IFormulasRegistryFormulaElementItem,
} from '@app/core/interfaces/formulas-registry';
import { IGridRequestPayload, IPagedResponse } from 'web-frontend-component-library/grid/interfaces';
import { MeasureObjectFuelRelocationPageRequest } from '@generatedDto/models';
import { INetworkConfigurationsDictionaryParams, INetworkConfigurationsSectionsDictionaryParams } from '@app/core/interfaces/network-configuration';

@Injectable({
  providedIn: 'root',
})
export class DictionaryApiService {
  constructor(private httpClient: HttpClient, private utilsService: UtilsService) { }

  // Справочник "Адреса" (локальные)
  public getAddressDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/address/object/local`, params)
      .pipe(share());
  }

  // Справочник "Адреса - совмещенный справочник объектов и элементов" (локальные)
  public getCompositeAddressDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/composite-address/object/local`, params)
      .pipe(share());
  }

  // Справочник "Вид оборудования" (список)
  public getDeviceTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/device-type`, {
        codes: codes,
        isShortName: isShortName,
      })
      .pipe(share());
  }

  // Справочник "Тип ресурса" (список)
  public getResourceTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/resource-type`, {
        codes: codes,
        isShortName: isShortName,
      })
      .pipe(share());
  }

  // Справочник "Роли пользователя"
  public getRolesDictionary(params: IUserRoleDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/user-role`, params)
      .pipe(share());
  }

  // Справочник "Группы пользователя" (список)
  public getGroupsList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/group`, {}).pipe(share());
  }

  // Справочник "Группы пользователя"
  public getGroupsDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/user-group`, params)
      .pipe(share());
  }

  // Справочник "Производитель" (список)
  public getVendorsList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/vendor`, {}).pipe(share());
  }

  // Справочник "Производитель"
  public getVendorsDictionary(params: IVendorDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/vendor`, params).pipe(share());
  }

  // Справочник "Наименование средства измерения" (список)
  public getMeasureToolsList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-tool`, {})
      .pipe(share());
  }

  // Справочник "Наименование средства измерения"
  public getMeasureToolsDictionary(params: IMeasureToolDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-tool`, params)
      .pipe(share());
  }

  // Справочник "Наименование средства измерения"
  public getMeasureToolTypeDictionary(params: IMeasureToolTypeDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-tool-type`, params)
      .pipe(share());
  }

  // Справочник "Модель оборудования" (список)
  public getDeviceModelsList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/device-model`, {})
      .pipe(share());
  }

  // Справочник "Модель оборудования"
  public getDeviceModelsDictionary(params: IDeviceModelDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/device-model`, params)
      .pipe(share());
  }

  // Справочник "Организации"
  public getOrganizationsDictionary(params: IOrganizationDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/organization`, params)
      .pipe(share());
  }

  // Справочник "Роли организации"
  public getOrganizationRoleDictionary(params: IOrganizationRoleDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/organization-role`, params)
      .pipe(share());
  }

  // Справочник "Тип системы" (список)
  public getSystemTypesList(params: ISystemTypesParams = {}): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/system-type`, params)
      .pipe(share());
  }

  // Справочник "Тип точки учета" (список)
  public getMeasurePointTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-point-type`, {})
      .pipe(share());
  }

  // Справочник "Тип подключения" (список)
  public getLinkTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/link-type`, {})
      .pipe(share());
  }

  // Справочник "Тип учета" (список)
  public getAccountingTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/accounting-type`, {})
      .pipe(share());
  }

  // Справочник "Вид коммерческого учета" (список)
  public getComAccountingTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/comm-accounting-type`, {})
      .pipe(share());
  }

  // Справочник "Элементы UI"
  public getUiObjectDictionary(params: IUiObjectDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/ui-object`, params)
      .pipe(share());
  }

  // Справочник "Тип холодного водоснабжения" (список)
  public getWaterSystemTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/water-system-type`, {})
      .pipe(share());
  }

  // Справочник "Способ водоподготовки" (список)
  public getWaterTreatmentTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/water-treatment-type`, {})
      .pipe(share());
  }

  // Справочник "Тип теплоносителя" (список)
  public getHeatTransferTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/heat-transfer-type`, {})
      .pipe(share());
  }

  // Справочник "Тип сети" (список)
  public getNetworkTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/network-type`, {})
      .pipe(share());
  }

  // Справочник "Тип объекта учета"
  public getMeasureObjectTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-object-type`, params)
      .pipe(share());
  }

  // Справочник "Тип объекта учета"
  public getMeasureObjectTypeTreeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/measure/object/dict/type`, params)
      .pipe(share());
  }

  // Справочник "Адреса. Состояние дома" (список)
  public getAddressBuildingStateList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/address-building-state`, {})
      .pipe(share());
  }

  // Справочник "Адреса. Состояние дома"
  public getAddressBuildingStateDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/address-building-state`, params)
      .pipe(share());
  }

  // Справочник "Адреса. Уровни"
  public getAddressObjectLevelList(isOnlyVisibleRecords: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/address-object-level`, {
        isOnlyVisibleRecords: isOnlyVisibleRecords,
      })
      .pipe(share());
  }

  // Справочник "Адреса. Вид строения" (список)
  public getAddressBuildingTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/address-building-type`, {
        codes: codes,
        isShortName: isShortName,
      })
      .pipe(share());
  }

  // Справочник "Адреса. Вид строения"
  public getAddressBuildingTypeDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/address-building-type`, params)
      .pipe(share());
  }

  // Справочник "Адреса. Вид владения (недвижимости)" (список)
  public getAddressEstateTypeList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/address-estate-type`, {
        codes: codes,
        isShortName: isShortName,
      })
      .pipe(share());
  }

  // Справочник "Адреса. Вид владения (недвижимости)"
  public getAddressEstateTypeDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/address-estate-type`, params)
      .pipe(share());
  }

  // Справочник "Адреса. Тип и сокращение адресообразующего элемента"
  public getAddressObjectTypeDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/address-object-type`, params)
      .pipe(share());
  }

  // Справочник "Адреса. Адресообразующий элемент"
  public getAddressTreeDictionary(params: IAddressTreeDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/address-tree`, params)
      .pipe(share());
  }

  // Справочник "Коэффициент трансформации"
  public getTransformationRatio(params: ITransformRatioDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/transformation-ratio`, params)
      .pipe(share());
  }

  // Справочник "Тип прибора учета" (список)
  public getDeviceMeterType(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/device-meter-type`, {})
      .pipe(share());
  }

  // Справочник "Тип Коэффициента трансформации" (список)
  public getTransformationRatioType(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/transformation-ratio-type`, {})
      .pipe(share());
  }

  // Справочник "Id объектов учета"
  public getMeasureObjectIdDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/measure/object/dict/ids`, params)
      .pipe(share());
  }

  // Справочник "Id оборудования"
  public getDeviceIdDictionary(params: IDeviceDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(`${environment.API_URL}/device/dict/ids`, params).pipe(share());
  }

  // Справочник "Id точек учета"
  public getMeasurePointIdDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(`${environment.API_URL}/measure/point/dict/ids`, params).pipe(share());
  }

  // Справочник "Адреса. Серийный номер оборудования"
  public getDeviceSerialNumberDictionary(params: IDeviceDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(`${environment.API_URL}/device/dict/serials`, params).pipe(share());
  }

  // Справочник "Статусы объекта (ОУ, УСПД, УПД, ПУ, ТУ, ИУ)" (список)
  public getMeasureObjectStateList(
    codes: MeasureObjectType[],
    isShortName: boolean = false,
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/measure/object/dict/state/list`, {
        codes: codes,
        isShortName: isShortName,
      })
      .pipe(share());
  }

  // Справочник "Часовые зоны"
  public getTimeZoneDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/time-zone`, params)
      .pipe(share());
  }

  /**
   * Список временных зон
   */
  public getTimeZoneList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/time-zone`, {})
      .pipe(share());
  }

  // Справочник "Уровень сигнала GSM" (список)
  public getGsmSignalLevelList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gsm-signal-level`, {})
      .pipe(share());
  }

  // Справочник "Тип активного подключения" (список)
  public getActiveConnectTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/active-connection-type`, {})
      .pipe(share());
  }

  // Справочник "Тип трубопровода" (список)
  public getPipelineTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/pipeline-type`, {})
      .pipe(share());
  }

  // Справочник "Четность прибора учета" (список)
  public getDeviceMeterParityList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/device-meter-parity`, {})
      .pipe(share());
  }

  // Справочник "Тип подключения модема" (список)
  public getModemConnectTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/modem-connection-type`, {})
      .pipe(share());
  }

  // Справочник "Тип порта" (список)
  public getPortTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/port-type`, {})
      .pipe(share());
  }

  // Справочник "Тип TCP порта" (список)
  public getTcpPortTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/tcp-port-type`, {})
      .pipe(share());
  }

  // Справочник "Номер порта УСД" (список)
  public getUsdPortNumberList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/usd-port-number`, {})
      .pipe(share());
  }

  // Справочник "Пользователи"
  public getUserDictionary(params: IUserDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/user`, params).pipe(share());
  }

  // Справочник "Тип события"
  public getEventTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/event-type`, params)
      .pipe(share());
  }

  // Справочник "Категория события"
  public getEventCategoryDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/event-category`, params)
      .pipe(share());
  }

  // Справочник "Тип события" для микросервисов
  public getEventTypeDictionaryForEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(`${environment.API_URL}/event/log/dict/${remoteService}/event/type`)
      .pipe(share());
  }

  // Справочник "Категория события" для микросервисов
  public getEventCategoryDictionaryForEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(`${environment.API_URL}/event/log/dict/${remoteService}/event/category`)
      .pipe(share());
  }

  /**
   * Справочник Тип события для микросервисов Журнала событий, для определенной категории.
   * @param remoteService сервис
   * @param categoryId категория
   */
  public getEventTypeDictionaryForEventLogCategory(
    remoteService: string,
    categoryId: number,
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(`${environment.API_URL}/event/log/dict/${remoteService}/event/type/${categoryId}`)
      .pipe(share());
  }

  // Справочник "Тип события" для микросервисов для Личного кабинета
  public getEventTypeDictionaryForProfileEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(`${environment.API_URL}/profile/event/log/dict/${remoteService}/event/type`)
      .pipe(share());
  }

  // Справочник "Категория события" для микросервисов для Личного кабинета
  public getEventCategoryDictionaryForProfileEventLog(remoteService: string): Observable<ISelectItem[]> {
    return this.httpClient
      .get<ISelectItem[]>(`${environment.API_URL}/profile/event/log/dict/${remoteService}/event/category`)
      .pipe(share());
  }

  // Справочник "Единицы измерения физических величин" (список)
  public getMeasureUnitList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-unit`, {})
      .pipe(share());
  }

  // Справочник "Тип данных прибора учета" (список)
  public getMeasureParamTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-param-type`, {})
      .pipe(share());
  }

  // Справочник "Параметры учета ресурсов" (список)
  public getMeasureParamList(
    measureParamTypeId?: number,
    resourceTypeCode?: ResourceTypeCode,
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-param`, {
        measureParamTypeId: measureParamTypeId,
        resourceType: resourceTypeCode,
      })
      .pipe(share());
  }

  // Справочник "Режим работы теплосчетчика" (список)
  public getHeatMeterWorkModeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/heat-meter-work-mode`, {})
      .pipe(share());
  }

  // Справочник "Тип фотографии" (список)
  public getPhotoTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/photo-type`, {})
      .pipe(share());
  }

  // Справочник "Тип документа" (список)
  public getDocumentTypeList(codes: string[], documentCategory: DocumentCategory = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/document-type`, {
        codes: codes,
        category: documentCategory,
      })
      .pipe(share());
  }

  /**
   * Справочник "Тип пароля электросчетчика" (список)
   * @param codes
   */
  public getEEPasswordTypeList(codes: string[]): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/password-type-ee`, {
      codes,
    });
  }

  // Справочник "Тип события ИВК" (список)
  public getIvkEventTypeList(codes: string[] = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/ivk-event-type`, { codes: codes })
      .pipe(
        map((items: ISelectItem[]) => {
          const result: ISelectItem[] =
            items && items.length > 0
              ? items.map((l) => {
                return {
                  key: l.ext && l.ext.code ? l.ext.code : l.key,
                  value: l.value,
                };
              })
              : [];
          return result;
        }),
        share(),
      );
  }

  /**
   * Справочник Режим работы TCP порта
   */
  public getTcpPortModeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/tcp-port-mode`, {})
      .pipe(share());
  }

  /**
   * Справочник Класс точности счетчиков электроэнергии
   * @param codes коды
   * @returns
   */
  public getPuEeAccuracyClassList(codes: string[] = []): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/accuracy-class-ee`, { codes })
      .pipe(share());
  }

  /**
   * Справочник Тип загрузки
   */
  public getUploadTypesList(placeCallCode: EUploadTypeCallPlaceCode = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/import-type`, { placeCallCode })
      .pipe(share());
  }

  /**
   * Справочник Статусы загрузки
   * @returns
   */
  public getUploadStatesList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/monitoring/import/dict/state`, {}).pipe(share());
  }

  // Справочник "Категории объекта учета" (список)
  public getMeasureObjectCategoryList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-object-category`, {})
      .pipe(share());
  }

  // Справочник "Военный городок"
  public getMilTownDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/military-town`, params)
      .pipe(share());
  }

  // Справочник "Номер ВГ по паспорту"
  public getMilTownPassportNumberDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/military-town-passport-number`, params)
      .pipe(share());
  }

  // Справочник "Реестровый номер ВГ"
  public getMilTownRegistryNumberDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/military-town-registry-number`, params)
      .pipe(share());
  }

  // Справочник "Номер по ГП"
  public getMOGenplanNumberDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-object-genplan-number`, params)
      .pipe(
        map((item) => {
          if (item.elements && item.elements.length > 0) {
            item.elements = item.elements.map((el) => {
              return { key: el.value, value: el.value };
            });
          }
          return item;
        }),
        share(),
      );
  }

  // Справочник "Место установки ПУ" (список)
  public getDeviceMeterLocationList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/device-meter-location`, {})
      .pipe(share());
  }

  // Справочник "Вид договора поставки" (список)
  public getDeliveryContractTypeList(onlyActual: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/delivery-contract-type`, {
        onlyActual: onlyActual,
      })
      .pipe(share());
  }

  // Справочник "Параметры договора поставки"
  public getDeliveryContractParamDictionary(
    params: IDeliveryContractParamDictionaryParams,
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/delivery-contract-param`, params)
      .pipe(share());
  }

  // Справочник "Военный округ" (список)
  public getMilitaryDistrictList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/military-district`, {})
      .pipe(share());
  }

  // Справочник "Исполнение теплообменного оборудования" (список)
  public getHeatExchangerImplTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/heat-exchanger-impl-type`, {})
      .pipe(share());
  }

  // Справочник "Тип теплообменного оборудования" (список)
  public getHeatExchangerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/heat-exchanger-type`, {})
      .pipe(share());
  }

  // Справочник "Тип инженерного оборудования" (список)
  public getEngineeringEquipmentTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/engineering-equipment-type`, {})
      .pipe(share());
  }

  // Справочник "Тип инженерного оборудования"
  public getEngineeringEquipmentTypeDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/engineering-equipment-type`, params)
      .pipe(share());
  }

  // Справочник "Причина аварии" (список)
  public getBreakdownReasonList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/breakdown-reason`, {})
      .pipe(share());
  }

  // Справочник "Вид аварии" (список)
  public getBreakdownTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/breakdown-type`, {}).pipe(
      map((items: ISelectItem[]) => {
        const result: ISelectItem[] =
          items && items.length > 0 ? this.utilsService.getSelectListWithChildren(items) : [];
        return result;
      }),
      share(),
    );
  }

  // Справочник "Принадлежность аварии" (список)
  public getBreakdownOwnList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/breakdown-own`, {}).pipe(
      map((items: ISelectItem[]) => {
        const result: ISelectItem[] =
          items && items.length > 0 ? this.utilsService.getSelectListWithChildren(items) : [];
        return result;
      }),
      share(),
    );
  }

  // Справочник "Тип компрессора" (список)
  public getCompressorTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/compressor-type`, {})
      .pipe(share());
  }

  // Справочник "Тип присоединения" (список)
  public getConnectionTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/connection-type`, {})
      .pipe(share());
  }

  // Справочник "Тип водозабора" (список)
  public getWaterWithdrawalTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/water-withdrawal-type`, {})
      .pipe(share());
  }

  // Справочник "Тип нагрузки привода редуктора давления" (список)
  public getReducerLoadTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/reducer-load-type`, {})
      .pipe(share());
  }

  // Справочник "Класс газопровода" (список)
  public getGasPipelineCategoryList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gas-pipeline-category`, {})
      .pipe(share());
  }

  // Справочник "Тип оборудования водоподготовки" (список)
  public getWaterEquipmentTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/water-equipment-type`, {})
      .pipe(share());
  }

  // Справочник "Вид котла" (список)
  public getBoilerKindList(boilerTypeId: number = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/boiler-kind`, { boilerTypeId: boilerTypeId })
      .pipe(share());
  }

  // Справочник "Тип котла" (список)
  public getBoilerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/boiler-type`, {})
      .pipe(share());
  }

  // Справочник "Тип деаэратора" (список)
  public getDeaeratorTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/deaerator-type`, {})
      .pipe(share());
  }

  // Справочник "Тип декарбонизатора" (список)
  public getDecarbonizerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/decarbonizer-type`, {})
      .pipe(share());
  }

  // Справочник "Направление вращения вентилятора" (список)
  public getFanDirectionList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fan-direction`, {})
      .pipe(share());
  }

  // Справочник "Назначение вентилятора дымоудаления" (список)
  public getFanPurposeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fan-purpose`, {})
      .pipe(share());
  }

  // Справочник "Тип измеряемой среды газоанализатора" (список)
  public getGasAnalyzerMediaList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gas-analyzer-media`, {})
      .pipe(share());
  }

  // Справочник "Тип газоанализатора" (список)
  public getGasAnalyzerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gas-analyzer-type`, {})
      .pipe(share());
  }

  // Справочник "Тип газораспределительного устройства" (список)
  public getGasDistributionTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gas-distribution-type`, {})
      .pipe(share());
  }

  // Справочник "Материал изготовления" (список)
  public getManufactureMaterialList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/manufacture-material`, {})
      .pipe(share());
  }

  // Справочник "Вид проверки электротехнической лабораторией"
  public getElectrInspectTypeDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/electr-inspect-type`, params)
      .pipe(share());
  }

  // Справочник "Тип химического реагента" (список)
  public getReagentTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/reagent-type`, {})
      .pipe(share());
  }

  // Справочник "Тип фильтрующего элемента" (список)
  public getFilterElementList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/filter-element`, {})
      .pipe(share());
  }

  // Справочник "Методика поверки"
  public getVerificationMethodDictionary(params: IVerificationMethodDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/verification-method`, params)
      .pipe(share());
  }

  // Справочник "Нормы температуры в обслуживаемой зоне помещений различного назначения" (список)
  public getRoomTemperNormList(roomTemperNormEnum: RoomTemperNormType): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/room-temper-norm`, {
        roomTemperNormEnum: roomTemperNormEnum,
      })
      .pipe(share());
  }

  // Справочник "Температура воздуха наиболее холодной пятидневки"
  public getPlaceLowTemperDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/place-low-temper`, params)
      .pipe(share());
  }

  // Справочник "Категория помещения" (список)
  public getRoomCategoryList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/room-category`, {})
      .pipe(share());
  }

  // Справочник "Тип топлива" (список)
  public getFuelTypeList(fuelKindCode: any = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fuel-type`, { fuelKindCode })
      .pipe(share());
  }

  // Справочник "Вид топлива" (список)
  public getFuelKindList(fuelTypeCode: FuelTypeCode = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fuel-kind`, { fuelTypeCode: fuelTypeCode })
      .pipe(share());
  }

  // Справочник "Тип изоляции трубопровода" (список)
  public getPipelineIsolationTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/pipeline-isolation-type`, {})
      .pipe(share());
  }

  // Справочник "Вид отопления" (список)
  public getHeatingKindList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/heating-kind`, {})
      .pipe(share());
  }

  // Справочник "Тип запорно регулирующего устройства" (список)
  public getWellheadValveTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/wellhead-valve-type`, {})
      .pipe(share());
  }

  // Справочник "Исполнение запорно регулирующего устройства" (список)
  public getWellheadValveImplTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/wellhead-valve-impl-type`, {})
      .pipe(share());
  }

  // Справочник "Марка деаэратора"
  public getDeaeratorBrandDictionary(params: IDeaeratorBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/deaerator-brand`, params)
      .pipe(share());
  }

  // Справочник "Сезоны"
  public getSeasons(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/seasons`, {}).pipe(share());
  }

  // Справочник "Тип прокладки трубопровода"
  public getPipingType(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/pipeline-gasket-type`, {})
      .pipe(share());
  }

  // Справочник "Тип насоса" (список)
  public getPumpTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/pump-type`, {})
      .pipe(share());
  }

  // Справочник "Тип электродвигателя"
  public getMotorType(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/electromotor-type`, {})
      .pipe(share());
  }

  // Справочник "Назначение электродвигателя"
  public getMotorPurpose(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/electromotor-purpose`, {})
      .pipe(share());
  }

  // Справочник "Марка дизельного топлива"
  public getDieselFuelBrand(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/diesel-fuel-brand`, {})
      .pipe(share());
  }

  // Справочник "Напряжение электродвигателя"
  public getMotorVoltage(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/electromotor-voltage`, {})
      .pipe(share());
  }

  // Справочник "Тип компенсирующего устройства"
  public getCompensatingDevice(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/compensating-device-type`, {})
      .pipe(share());
  }

  // Справочник "Группа шаблона ведомости показаний ПУ"
  public getMeasureReportTmplGroupList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-report-tmpl-group`, {})
      .pipe(share());
  }

  // Справочник "Тип шаблона ведомости показаний ПУ"
  public getMeasureReportTmplTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-report-tmpl-type`, {})
      .pipe(share());
  }

  // Справочник "Марка шкафа управления наружным освещением"
  public getLightingControlBoxBrand(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/lighting-control-box-brand`, params)
      .pipe(share());
  }

  // Справочник "Тип клапана"
  public getValveTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/valve-type`, {})
      .pipe(share());
  }

  // Справочник "Марка декарбонизатора"
  public getDecarbonizerBrandDictionary(params: IDecarbonizerBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/decarbonizer-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка клапана"
  public getValveBrandDictionary(params: IValveBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/valve-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка редуктора давления"
  public getReducerBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/reducer-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка газоанализатора"
  public getGazAnalyzerBrandDictionary(params: IGazAnalyzerBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/gas-analyzer-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка оборудования химводоподготовки"
  public getChemicalTreatmentSystemBrandDictionary(
    params: IChemicalTreatmentSystemBrandDictionaryParams,
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/chemical-treatment-system`, params)
      .pipe(share());
  }

  // Справочник "Марка запорно-регулирующей арматуры"
  public getWellheadFittingBrandDictionary(params: IWellheadFittingBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/wellhead-fitting-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка электродвигателя"
  public getElectromotorBrandDictionary(params: IElectromotorBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/electromotor-brand`, params)
      .pipe(share());
  }

  // Справочник "Шаблон ВП"
  public getMeasureReportTemplateDictionary(
    params: IMeasureReportTemplateDictionaryParams,
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-report-template`, params)
      .pipe(share());
  }

  // Справочник "Марка котла"
  public getBoilerBrandDictionary(params: IBoilerBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/boiler-brand`, params)
      .pipe(share());
  }

  // Справочник "Тип экономайзера"
  public getEconomizerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/economizer-type`, {})
      .pipe(share());
  }

  // Справочник "Тип утепления емкости запаса воды"
  public getWaterTankInsulationTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/water-tank-insulation-type`, {})
      .pipe(share());
  }

  // Справочник "Тип обогрева емкости запаса воды"
  public getWaterTankHeatingTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/water-tank-heating-type`, {})
      .pipe(share());
  }

  // Справочник "Назначение насоса"
  public getPumpPurposeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/pump-purpose`, {})
      .pipe(share());
  }

  // Справочник "Марка насоса"
  public getPumpBrandDictionary(params: IPumpBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/pump-brand`, params)
      .pipe(share());
  }

  // Справочник "Материал корпуса запорно-регулирующей арматуры"
  public getWellheadFittingMaterialList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/wellhead-fitting-material`, {})
      .pipe(share());
  }

  // Справочник "ID инженерного оборудования"
  public getEngineeringDeviceIdDictionary(params: IEngineeringDeviceIdDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/device/engineering/dict/ids`, params)
      .pipe(share());
  }

  // Справочник "Марка экономайзера"
  public getEconomizerBrandDictionary(params: IEconomizerBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/economizer-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка теплообменного оборудования"
  public getHeatExchangerBrandDictionary(params: IHeatExchangerBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/heat-exchanger-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка дымовой трубы"
  public getChimneyBrandDictionary(params: IChimneyBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/chimney-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка компрессора"
  public getCompressorBrandDictionary(params: ICompressorBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/compressor-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка дизельгенератора"
  public getDieselGeneratorBrandDictionary(params: IDieselGeneratorBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/diesel-generator-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка вентилятора"
  public getFanBrandDictionary(params: IFanBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/fan-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка шкафа управления наружным освещением"
  public getLightingControlBoxBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/lighting-control-box-brand`, params)
      .pipe(share());
  }

  /**
   * Динамическая статусная модель. Справочник статусов.
   * @param params параметры
   */
  public getStatusModelStatusesDictionary(params: ISMStatusesDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/usm-state`, params)
      .pipe(share());
  }

  /**
   * Динамическая статусная модель. Справочник переходов в другой статус
   * @param objectTypeId ID типа объекта СМ
   */
  public getStatusModelConditionsList(objectTypeId: number): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/usm-condition`, { objectTypeId })
      .pipe(share());
  }

  /**
   * Динамическая статусная модель. Справочник пост-функций
   * @param objectTypeId ID типа объекта СМ
   */
  public getStatusModelPostFunctionsList(objectTypeId: number): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/usm-post-function`, { objectTypeId })
      .pipe(share());
  }

  /**
   * Динамическая статусная модель. Справочник триггеров
   * @param objectTypeId ID типа объекта СМ
   */
  public getStatusModelTriggersList(objectTypeId: number): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/usm-trigger`, { objectTypeId })
      .pipe(share());
  }

  /**
   * Динамическая статусная модель. Справочник Типа условий (Criteria)
   */
  public getStatusModelConditionTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/usm-condition-type`, {})
      .pipe(share());
  }

  // Справочник "Марка испарительной установки"
  public getEvaporPlantBrandDictionary(params: IEvaporPlantBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/evapor-plant-brand`, params)
      .pipe(share());
  }

  // Справочник "Тип выключателя"
  public getSwitchTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/switch-type`, {})
      .pipe(share());
  }

  // Справочник "Статус ИО" (список)
  public getEngineerDeviceStateList(
    codes: string[] = ['EEQ_DEVICE'],
    isShortName: boolean = false,
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/device/engineering/state/list`, {
        codes: codes,
        isShortName: isShortName,
      })
      .pipe(share());
  }

  // Справочник "Тип датчика давления газа"
  public getGasPressureSensorTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gas-pressure-sensor-type`, {})
      .pipe(share());
  }

  // Справочник "Трубопровод задвижки"
  public getGateValvePipelineList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gate-valve-pipeline`, {})
      .pipe(share());
  }

  // Справочник "Вид задвижки"
  public getGateValveKindList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/gate-valve-kind`, {})
      .pipe(share());
  }

  // Справочник "Тип испарительной установки"
  public getEvaporPlantTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/evapor-plant-type`, {})
      .pipe(share());
  }

  // Справочник "Сорт угля"
  public getCoalGradeList(fuelBrandId: number = null): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/coal-grade`,
        { fuelBrandId: fuelBrandId })
      .pipe(share());
  }

  // Справочник "Марка выключателя"
  public getSwitchBrandDictionary(params: ISwitchBrandDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/switch-brand`, params)
      .pipe(share());
  }

  // Режимные карты. Идентификаторы. Справочник
  public getRegimeMapDictionary(params: IDictionaryParams & { resourceTypeCode?: ResourceTypeCode, timeZoneId?: number }): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(`${environment.API_URL}/regime-map/template/dict/ids`, params);
  }

  // Справочник "Марка агрегата"
  public getUnitBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/unit-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка ячейки РУ"
  public getCellRuBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/cell-ru-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка трансформатора тока"
  public getTransformerAmperageBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/transformer-amperage-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка трансформатора напряжения"
  public getTransformerVoltageBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/transformer-voltage-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка устройства РЗА"
  public getRzaBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/rza-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка панели 0.4 кВ"
  public getPanelBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/panel-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка фильтра"
  public getFilterBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/filter-brand`, params)
      .pipe(share());
  }

  // Справочник "Мнемосхемы"
  public getMimicPanelDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/mimic-panel`, params)
      .pipe(share());
  }

  /**
   * Справочник Температурные графики
   * @param params параметры
   */
  public getTemperatureGraphsDictionary(params: ITGDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/temperature-graph`, params)
      .pipe(share());
  }

  // Справочник "Тип термометра"
  public getThermometerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/thermometer-type`, {})
      .pipe(share());
  }

  // Справочник "Наименование агрегата"
  public getUnitNameList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/unit-name`, {})
      .pipe(share());
  }

  // Справочник "Схема подключения теплообменного оборудования"
  public getHeatExchangerSchemeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/heat-exchanger-scheme`, {})
      .pipe(share());
  }

  // Справочник "Класс точности трансформатора"
  public getTransformerAccuracyClassList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/transformer-accuracy-class`, {})
      .pipe(share());
  }

  // Справочник "Тип трансформатора напряжения"
  public getTransformerVoltageTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/transformer-voltage-type`, {})
      .pipe(share());
  }

  // Справочник "Класс напряжения"
  public getVoltageClassList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/voltage-class`, {})
      .pipe(share());
  }

  // Справочник "Тип манометра"
  public getManometerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/manometer-type`, {})
      .pipe(share());
  }

  // Справочник "Тип бака-аккумулятора"
  public getStorageTankTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/storage-tank-type`, {})
      .pipe(share());
  }

  // Справочник "Тип гильзы"
  public getSleeveTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/sleeve-type`, {})
      .pipe(share());
  }

  // Справочник "Марка манометра"
  public getManometerBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/manometer-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка термометра"
  public getThermometerBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/thermometer-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка газораспределительного устройства"
  public getGasDistribBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/gas-distrib-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка трансформаторной подстанции"
  public getTransformerSubstBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/transformer-subst-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка паропровода"
  public getSteamLineBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/steam-line-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка газопровода"
  public getGasPipelineBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/gas-pipeline-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка тепловой сети"
  public getHeatingNetworkBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/heating-network-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка емкости запаса воды"
  public getWaterStorageBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/water-storage-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка распределительного устройства"
  public getSwitchgearBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/switchgear-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка бака-аккумулятора"
  public getStorageTankBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/storage-tank-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка гильзы"
  public getSleeveBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/sleeve-brand`, params)
      .pipe(share());
  }

  // Справочник "Марка водонапорной башни"
  public getWaterTowerBrandDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/water-tower-brand`, params)
      .pipe(share());
  }

  // Справочник "Должность"
  public getJobPositionsDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/job-position`, params)
      .pipe(share());
  }

  // Справочник "Результат ПСИ"
  public getPsiResultList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/psi-result`, {})
      .pipe(share());
  }

  /**
   * Справочник Реестр формул
   * @param params параметры
   */
  public getFormulasRegistryDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/formula-registry`, params)
      .pipe(share());
  }

  // Справочник Сегменты системы
  public getSystemSegmentList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/system-segment`, {})
      .pipe(share());
  }

  /**
   * Справочник Статусы формул
   */
  public getFormulasRegistryStatesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/formula-state`, {})
      .pipe(share());
  }

  /**
   * Справочник Тип элемента формулы досчета ВП
   */
  public getFormulasRegistryFormulaElementTypesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-report-calc-item-type`, {})
      .pipe(share());
  }

  /**
   * Справочник Элементы формулы досчета ВП
   * @param typeCode Код типа элемента
   */
  public getFormulasRegistryFormulaElementsList(
    typeCode: string = null,
  ): Observable<ISelectItem<IFormulasRegistryFormulaElementItem>[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/measure-report-calc-item`, { typeCode })
      .pipe(share());
  }

  /**
   * Справочник Элементы формулы досчета ВП
   * @param typeCode Код типа элемента
   */
  public getFormulasRegistryFormulaElementsDictionary(
    params: IFormulasRegistryElementsDictionaryParams,
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-report-calc-item`, params)
      .pipe(share());
  }

  // Справочник "Подразделения организации"
  public getOrganizationDepartmentDictionary(
    params: IOrganizationDepartmentDictionaryParams,
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/organization-department`, params)
      .pipe(share());
  }

  // Справочник "Вид юридического лица"
  public getOrganizationKindDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/organization-kind`, params)
      .pipe(share());
  }

  // Справочник "Структура подразделения"
  public getOrganizationTreeDictionary(params: IOrganizationTreeDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/organization-tree`, params)
      .pipe(share());
  }

  // Справочник "Тип узла в стуктуре организации"
  public getOrganizationTreeTypeDictionary(params: IOrganizationTreeTypeDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/organization-tree-type`, params)
      .pipe(share());
  }

  // Грид Головная организация для расширенного справочника
  public getParentOrganization(requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    return this.httpClient
      .post<IPagedResponse>(`${environment.API_URL}/admin/organization/main/grid`, requestPayload)
      .pipe(share());
  }

  // Справочник "Метод округления" (список)
  public getRoundTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/rounding-method`, {})
      .pipe(share());
  }

  // Справочник «Категории ПС (проблемных ситуаций)»
  public getIncidentCategoryDictionary(params: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/incident-category`, params)
      .pipe(share());
  }

  // Справочник "Вид ПС"
  public getIncidentTypeDictionary(
    params: IDictionaryParams & { incidentCategoryIds: number[] },
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/incident-type`, params)
      .pipe(share());
  }

  // Справочник идентификаторов "Ведомости показаний ПУ"
  public getReportIdsDictionary(params: IDictionaryParams & { measurePointId: number }): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.REPORT_URL}/measure/report/dict/ids`, params)
      .pipe(share());
  }

  //  Справочник идентификаторов "Версии ведомостей показаний ПУ"
  public getReportVersionIdsDictionary(
    params: IDictionaryParams & { measurePointId: number },
  ): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.REPORT_URL}/measure/report/version/dict/ids`, params)
      .pipe(share());
  }
  // Справочник «Код экземпляра системы»
  public getSystemCodeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/system-code`, {})
      .pipe(share());
  }

  // Справочник «Тип изменения топлива»
  public getFuelChangeTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fuel-change-type`, {})
      .pipe(share());
  }
  // Справочник «Место хранения топлива»
  public getFuelStorageList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fuel-storage`, {})
      .pipe(share());
  }
  // Справочник «Способ перемещения топлива»
  public getFuelTransferMethodList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/fuel-transfer-method`, {})
      .pipe(share());
  }
  // Справочник "статусов перемещения топлива"
  public fuelRelocationStateList(codes: string[], isShortName: boolean = false): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/measure/object/fuel/relocation/dict/state/list`, {
        codes,
        isShortName,
      })
      .pipe(share());
  }

  // Справочник «Объекты перемещения топлива»
  public getRelocationObjectsDictionary(params: MeasureObjectFuelRelocationPageRequest): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/measure/object/fuel/relocation/dict/objects`, params)
      .pipe(share());
  }

  /**
   * ОУ. Учет топлива. Перемещение. Справочник «Топливо перемещения»
   * @param params параметры
   * @returns
   */
  public getRelocationFuelsList(params: MeasureObjectFuelRelocationPageRequest): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/measure/object/fuel/relocation/dict/fuels`, params)
      .pipe(share());
  }

  //#region Справочники Согласования ВВП

  /**
   * Справочник «Статус согласования потребителем версии ведомости показаний ПУ»
   * @returns
   */
  public getMeasureReportApprovalConsumerStatesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/mrpt-agrm-consumer-state`, {})
      .pipe(share());
  }

  /**
   * Справочник «Статус согласования РСО версии ведомости показаний ПУ
   * @returns
   */
  public getMeasureReportApprovalRsoStatesList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/mrpt-agrm-rco-state`, {})
      .pipe(share());
  }

  /**
   * Справочник Тип графика архивных параметров
   * @returns
   */
  public getGraphTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/graph-type`, {})
      .pipe(share());
  }

  /**
   * Справочник Часовые зоны. Информация о группе часовых зон России
   * @returns
   */
  public getTimeZoneGroupInfo(utcOffset: string): Observable<ITimeZoneGroup> {
    return this.httpClient
      .post<ITimeZoneGroup>(`${environment.API_URL}/admin/time-zone/group-info`, { utcOffset: utcOffset })
      .pipe(share());
  }

  /**
   * Справочник Причины выполнения работ
   * @returns
   */
  public getWorkReasonList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/work-reason`, {})
      .pipe(share());
  }

  /**
   * Справочник Типы планов работ
   * @returns
   */
  public getPlanTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/plan-type`, {})
      .pipe(share());
  }

  /**
   * Справочник Наименования работ
   * @param params параметры
   */
  public getWorkTypeDictionary(params: IWorkTypeDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/work-type`, params)
      .pipe(share());
  }

  /**
   * Справочник Состояние оборудования
   * @returns
   */
  public getDeviceConditionList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/device-condition`, {})
      .pipe(share());
  }

  /**
   * Справочник «Статус выполнения работ»
   * @returns
   */
  public getWorkStatusList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/work-status`, {})
      .pipe(share());
  }

  //#endregion

  /**
   * Справочник «Доступные функции для динамической статусной модели»
   * @param code код типа объекта ДСМ
   */
  public getStatusModelAvailableFunctions(code: string): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/usm-function`, { code })
      .pipe(share());
  }

  /**
   * Справочник «Способ укладки»
   * @returns
   */
  public getLayingMethodList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(`${environment}/dictionary/drop-down/laying-method`, {}).pipe(share());
  }

  /**
   * Справочник «Тип прокладки»
   * @returns
   */
  public getLayingTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/laying-type`, {})
      .pipe(share());
  }

  /**
   * Справочник «Общероссийский классификатор единиц измерения»
   * @param payload параметры
   * @returns
   */
  public getMeasureUnitRussianDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/measure-unit-russian`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Тип линии электропередачи»
   * @param payload параметры
   * @returns
   */
  public getPowerLineTypeDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/power-line-type`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Уровень напряжения»
   * @param payload параметры
   * @returns
   */
  public getVoltageLevelDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/voltage-level`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Материал изоляции»
   * @param payload параметры
   * @returns
   */
  public getIsolationMaterialDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/isolation-material`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Вид объекта ОКИ»
   * @param payload параметры
   * @returns
   */
  public getOkiObjectKindDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/oki-object-kind`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Размеры инженерных узлов на сетях»
   * @param payload параметры
   * @returns
   */
  public getEngineeringNodeDimensionDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/engineering-node-dimension`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Материал инженерных сетей на узлах»
   * @param payload параметры
   * @returns
   */
  public getEngineeringNodeMaterialDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/engineering-node-material`, payload)
      .pipe(share());
  }

  /**
   * Справочник «ГОСТы (СНиПы) узлов на сетях»
   * @param payload параметры
   * @returns
   */
  public getEngineeringNodeGostDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/engineering-node-gost`, payload)
      .pipe(share());
  }

  /**
   * Справочник Справочник «Виды использования инженерных узлов на сетях»
   * @param payload параметры
   * @returns
   */
  public getEngineeringNodeUsageKindDictionary(payload: IDictionaryParamsWithActual): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/engineering-node-usage-kind`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Тип инженерного узла на сетях»
   * @returns
   */
  public getEngineeringNodeTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(`${environment.API_URL}/dictionary/drop-down/engineering-node-type`, {})
      .pipe(share());
  }

  /**
   * Справочник Статусы плана проведения обследования
   */
  public getCheckupPlanStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/checkup-plan-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Статусы мероприятия плана проведения обследования
   * @returns
   */
  public getCheckupPlanActionStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/checkup-plan-impl-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Состояние планов выполнения ремонтных работ»
   * @returns
   */
  public getMaintainencePlanStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/repair-plan-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Состояние мероприятия плана выполнения ремонтных работ»
   * @returns
   */
  public getMaintainencePlanActionStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/repair-plan-impl-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Регион»
   * @param payload параметры
   * @returns
   */
  public getRegionDictionary(payload: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/region`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Населенный пункт»
   * @param payload параметры
   * @returns
   */
  public getRegionPlaceDictionary(payload: IRegionPlaceDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient
      .post<IDictionaryBody>(`${environment.API_URL}/dictionary/page/place`, payload)
      .pipe(share());
  }

  /**
   * Справочник «Тип котлоагрегата»
   * @returns
   */
  public getBoilerUnitTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/boiler-unit-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Тип форсунок»
   * @returns
   */
  public getInjectorTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/injector-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Марка топлива»
   * @returns
   */
  public getFuelBrandList(
    fuelTypeId: number = null,
    fuelKindId: number = null,
    coalGradeId: number = null
  ): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/fuel-brand`,
      { fuelTypeId: fuelTypeId, fuelKindId: fuelKindId, coalGradeId: coalGradeId }
    ).pipe(share());
  }

  //#region Meteostations

  /**
   * Справочник ID метеостанций
   * @param params параметры
   * @returns
   */
  public getMeteostationsIdsDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/meteo/station/dict/ids`,
      params
    ).pipe(share());
  }

  /**
   * Справочник Номера метеостанций
   * @param params параметры
   * @returns
   */
  public getMeteostationsNumbersDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/meteo/station/dict/numbers`,
      params
    ).pipe(share());
  }

  /**
   * Справочник Наименования метеостанций
   * @param params параметры
   * @returns
   */
  public getMeteostationsNamesDictionary(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/meteo-station`,
      params
    ).pipe(share());
  }
  //#endregion

  /**
   * Справочник «Статус завершения работ»
   * @returns
   */
  public getWorkCompletionStatusList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/work-completion-status`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Статус аварийных работ»
   * @returns
   */
  public getEmergencyWorkStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/emergency-work-status`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Состояние планов подготовки оборудования к осенне-зимнему периоду»
   * @returns
   */
  public getPreparationPlanStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/preparation-plan-state`,
      {}
    ).pipe(share());
  }
  /**
   * Справочник «Состояние мероприятия подготовки оборудования к осенне-зимнему периоду»
   */
  public getPreparationPlanImplStateList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/preparation-plan-impl-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Длительность ПС
   * @returns
   */
  public getIncidentDurationList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/incident-duration`,
      {}
    ).pipe(share())
  }

  /**
   * Справочник Метод расчета НУР
   * @returns
   */
  public getNurCalcMethodList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/nur-calc-method`,
      {}
    ).pipe(share())
  }

  /**
   * Справочник Тип слоевой топки
   * @returns
   */
  public getFireboxLayerTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/firebox-layer-type`,
      {}
    ).pipe(share())
  }

  /**
   * Справочник Содержание мелочи
   * @returns
   */
  public getContentTrifleList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/content-trifle`,
      {}
    ).pipe(share())
  }

  /**
   * Справочник Коэффициент К1
   * @returns
   */
  public getK1RatioList(
    boilerTypeTd: number,
    boilerUnitTypeId: number,
    coalGradeId: number,
    fuelKindId: number,
    fuelTypeId: number
  ): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/k1-ratio`,
      {
        boilerTypeTd: boilerTypeTd,
        boilerUnitTypeId: boilerUnitTypeId,
        coalGradeId: coalGradeId,
        fuelKindId: fuelKindId,
        fuelTypeId: fuelTypeId
      }
    ).pipe(share())
  }

  /**
   * Справочник Коэффициенты собственных нужд
   * @returns
   */
  public getOwnNeedsRatioList(
    boilerTypeTd: number = null,
    fuelTypeId: number = null,
    ownNeedsId: number = null
  ): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/own-needs-ratio`,
      {
        boilerTypeTd: boilerTypeTd,
        ownNeedsId: ownNeedsId,
        fuelTypeId: fuelTypeId
      }
    ).pipe(share())
  }

  /**
   * Справочник Собственные нужды
   * @returns
   */
  public getOwnNeedsList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/own-needs`,
      {}
    ).pipe(share())
  }

  /**
   * Справочник «Тип режимов работы сети»
   * @returns
   */
  public networkModeTypeList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/network-mode-type`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник Федеральный округ
   * @param params параметры
   * @returns
   */
  public getFederalDistrict(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/federal-district`,
      params
    ).pipe(share());
  }

  /**
   * Справочник Субъект РФ
   * @param params параметры
   * @returns
   */
  public getRFRegion(params: IDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/region-rf`,
      params
    ).pipe(share());
  }

  /**
   * Справочник Род (вид) войск
   * @param params параметры
   * @returns
   */
  public getTroopType(params: ITroopTypeDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/troop-type`,
      params
    ).pipe(share());
  }

  /**
   * Справочник Военный округ
   * @param params параметры
   * @returns
   */
  public getMilitaryDistrict(params: IMilitaryDistrictDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/military-district`,
      params
    ).pipe(share());
  }

  /**
  * Справочник «Конфигурации сети»
  * @param params параметры
  * @returns
  */
  public networkConfigurationsDictionary(params: INetworkConfigurationsDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/network-cfg`,
      params
    ).pipe(share());
  }

  /**
  * Справочник «Конфигурации участка сети»
  * @param params параметры
  * @returns
  */
  public networkConfigurationsSectionsDictionary(params: INetworkConfigurationsSectionsDictionaryParams): Observable<IDictionaryBody> {
    return this.httpClient.post<IDictionaryBody>(
      `${environment.API_URL}/dictionary/page/network-section-cfg`,
      params,
    ).pipe(share());
  }

  /*
   * Справочник «Статусы нештатных ситуаций»
   * @returns
   */
  public emergencyLogStatesList(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/emergency-situation-state`,
      {}
    ).pipe(share());
  }

  /**
   * Справочник «Статусы планов отключения»
   */
  public getResourceDisablePlanStatuses(): Observable<ISelectItem[]> {
    return this.httpClient.post<ISelectItem[]>(
      `${environment.API_URL}/dictionary/drop-down/shutoff-plan-state`,
      {}
    ).pipe(share());
  }
}
